.datereports {
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  .rs-picker-daterange-menu {
    left: unset !important;
    right: 185px !important;
    top: 150px !important;
    z-index: 99;
  }
  .rs-picker-daterange-header {
    display: none;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    background: transparent !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    width: 350px;
    color: #cbcbcb;
    border: 1px solid #cbcbcb;
    display: flex;
    flex-flow: row-reverse;
    padding: 11px;
    border-radius: 10px;
    align-items: center;
    padding-right: 32px;
    max-width: 100%;
  }
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    display: flex;
    color: black;
    // padding-top: 5px;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder,
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: black;
    font-weight: 500;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: #cbcbcb;
    border: 1px solid;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-month-dropdown-cell-active
    .rs-calendar-month-dropdown-cell-content {
    border-color: #0092ff;
    background-color: #0092ff;
    border-radius: 30px;
  }
  .rs-calendar-table-cell-in-range::before {
    background-color: #e3f1fb;
  }
  .rs-calendar-table-cell:not(.rs-calendar-table-cell-selected):hover
    .rs-calendar-table-cell-content,
  .rs-calendar-month-dropdown-cell:not(
      .rs-calendar-month-dropdown-cell-active
    ):hover
    .rs-calendar-month-dropdown-cell-content {
    background-color: #e3f1fb;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
    color: #0092ff;
  }
  .rs-calendar-month-dropdown-year-active {
    color: #0092ff;
  }
  .rs-calendar-month-dropdown-cell-content {
    font-size: 12px;
    padding: 7px 7px 7px 5px;
  }
  .rs-calendar-table-cell-content {
    font-size: 12px;
    color: #1d2634;
    padding: 7px;
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    padding: 6px;
  }
  .rs-picker-toolbar-ranges {
    margin-left: 0px;
    margin-top: 0px;
  }
  .rs-picker-toolbar-option {
    color: #0092ff !important;
    font-size: 11px;
    font-weight: 600;
    padding: 5px 20px;
    background: #e3f1fb;
    margin-right: 10px;
    border-radius: 100px;
  }
  .rs-picker-toolbar-option:not(:last-child)::before {
    display: none;
  }
  .rs-picker-toolbar-option:hover,
  .rs-picker-toolbar-option:active,
  .rs-picker-toolbar-option:focus {
    color: #0092ff;
    background: #e3f1fb;
  }
  .rs-picker-toolbar-option:active {
    border: 1px solid #0092ff;
  }
  .rs-picker-toolbar-right-btn-ok {
    background-color: #0092ff;
    font-size: 12px;
    font-weight: 600;
    border-radius: 25px;
    padding: 6px 18px;
  }
  .rs-picker-toolbar-right-btn-ok:hover {
    background-color: #0092ff;
  }
  .rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    width: 354px;
  }
}
