.virtual-accounts-success {
  div.close-success-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }

  .dialog-heading {
    margin-top: 10px;
    margin-bottom: 5px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
    }
  }

  .dialog-content-container {
    width: 475px;
    .container {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 475px;
      height: 255px;
      background: #f1f1f1;
      word-break: break-all;

      .tabs-block {
        display: flex;
        .tabs {
          padding: 15px;
          text-align: center;
          width: 50%;
          background: #fff;
          cursor: pointer;
          box-sizing: content-box;
          position: relative;
          outline: none;
        }
        .active-tabs {
          background: white;
          border-bottom: 1px solid transparent;
          font-weight: 600;
          color: #0092ff;
        }

        .active-tabs::before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 99%;
          height: 3px;
          background: #0092ff;
        }

        button {
          border: none;
        }
      }

      .content-tabs {
        flex-grow: 1;
      }
      .content {
        background: white;
        width: 100%;
        height: 100%;
        display: none;
      }
      .active-content {
        display: block;
      }
    }

    .success-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      height: 200px;
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #4d4f5c;
      }
    }
    .success-account-details {
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 200px;
      table {
        margin-left: 18px;
        td {
          font-size: 12px;
          font-weight: 500;
          color: #43425d;
          padding: 12px;
        }
        .accounts-heading {
          font-weight: 600;
          color: #43425d;
        }
        .accounts-details {
          font-weight: 500;
          color: #000000;
        }
        .copy-link-icon {
          cursor: pointer;
          width: 14px;
          margin-left: 8px;
          &:hover{
            opacity: 0.5;
          }
        }
      }
    }
    .success-btn-container {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      .success-btn-done {
        border: none;
        outline: none;
        padding: 7px 30px;
        border-radius: 100px;
        background: #0092ff;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
