.main_dialogue{
    min-height: 450px;
    background-color: white;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 670px;
    align-items: center;
    .error-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        padding: 3rem;
      
        img {
          margin-bottom: 1rem;
          width: 100%;
        }
      
        .error-text-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex-direction: column;
          color: #43425d;
          p {
            text-align: center;
          }
          padding-bottom: 10px;
        }
        .refresh-btn{
          width: max-content;
          padding: 8px 16px;
          background-color: #0092ff;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          border-radius: 30px;
          text-transform: capitalize;
        }
      }
}

