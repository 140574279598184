.manage-2fa-toggle-card {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 2rem;
  padding: 1rem;
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toggle-card-heading {
      color: #000;
      font-weight: 600;
    }
    //^   toggle styles
    .loading-text {
      font-size: 0.8rem;
    }
    .toggle-wrapper {
      display: flex;
      gap: 3rem;
      height: 15%;
      align-items: center;
      div.auth-toggle-container {
        display: flex;
        align-items: center;
        flex: 1;
        span {
          font-size: 14px;
          font-weight: 500;
          color: #43425d;
        }

        .toggle-section {
          display: flex;
          align-items: center;
          flex: 0.75;

          span {
            color: #cbcbcb;
            font-weight: 500;
            margin: 5px;
          }

          .text-toggle {
            transition: all 0.5s;
            color: #0092ff;
            font-weight: bold;
          }

          .toggle {
            position: relative;
            display: inline-block;
            width: 34px;
            height: 18px;
            background-color: #e8e9ec;
            border-radius: 30px;
          }

          .toggle:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #0092ff;
            left: 0px;
            transition: all 0.5s;
          }

          .checkbox:checked + .toggle::after {
            left: 16px;
            right: 1px;
          }

          .checkbox:checked + .toggle {
            background-color: #e8e9ec;
          }

          .checkbox {
            display: none;
          }
        }
      }
    }
  }

  // enable login text detail
  .enable-login-detail {
    text-align: left;
    margin-top: 1.4rem;
    font-size: 0.845rem;
  }
}
