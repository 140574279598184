.tooltip-container {
  position: relative;
  display: inline-flex;
  z-index: 50;

  .info-icon {
    background: #ffff;
    img {
      background: #ffff;
      width: 0.8rem;
      height: 0.8rem;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }
  .required-mark {
    color: red;
    margin-left: 2px;
  }

  .tooltip {
    // color: #000;
    // width: 14rem;
    position: absolute;
    border: 0.5px solid #0092ff;
    background: #f2f9ff;
    color: #43425d;
    padding: 5px 8px;
    border-radius: 3px;
    top: 100%;
    left: 70%;
    transform: translateX(-24%);
    z-index: 100;
    white-space: wrap;
    width: max-content;
    font-size: 0.75rem;
    z-index: 50;
  }
  .top {
    top: calc(32px * -1);
  }
}
