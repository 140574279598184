#heading {
  font-size: 17px;
  font-weight: 400;
  padding: 16px 0px;
  color: #43425d;
  font-weight: 500;
}
.ReportsMainDialog {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .reportsloader {
    align-self: center;
    padding: 5px;
  }
}

.reportsloaderBig {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-height: 600px) {
  .ReportsMainDialog {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  #heading {
    font-size: 17px;
    font-weight: 400;
    padding: 10px 0px;
    color: #43425d;
    font-weight: 500;
  }
}
@media only screen and (max-width: 768px) {
  .ReportsMainDialog {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  #heading {
    font-size: 17px;
    font-weight: 500;
    padding: 10px 0px;
    color: #43425d;
  }
}
