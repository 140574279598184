div.api-hits-graph-menu{
	position: absolute;
    top: -40px;
    right: 0;
    cursor: pointer;
    .api-hits-graph-dropdown{
		position: relative;
	    display: flex;
	    flex-flow: column;
	    align-items: center;
	    justify-content: center;
	    cursor: pointer;
		.api-hits-graph-dropdown-content{
			display: none;
			position: absolute;
			background-color: #FFFFFF;
			border-radius: 10px;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
			padding: 20px;
			top: 25px;
			right: 0px;
			z-index: 1;
			width: 200px;
			&.active{
				display: block;
			}
			form{
				button.apply-button{
					width: 100%;
					margin-top: 20px;
					padding: 10px 25px;
					border-radius: 100px;
					background: #0092FF;
					color: #FFFFFF;
					font-size: 13px;
					font-weight: 500;
					line-height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				ul{
					li:first-child{
						margin-top: 10px;
					}
					li{
						font-size: 15px;
						font-weight: 500;
						color: #2C2C2C;
						margin-top: 20px;
						span.MuiCheckbox-root{
							padding: 0;
							span.MuiIconButton-label{
								margin-top: -2px;
								.MuiSvgIcon-root{
									fill: #2C2C2C;
									font-size: 1.3rem;
									margin-right: 5px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.api-hits-graph-block{
	margin-top: 0px;
	display: block;
	width: 100%;
	height: 250px;
}
.custom-tooltip{
	background: #FFFFFF;
	border-radius: 10px;
	padding: 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
	.date{
		color: #43425D;
		font-weight: 500;
	}
	.total{
		color: #0092FF;
		margin-top: 10px;
		span{
			font-weight: 400;
		}
	}
	.successful{
		color: #4AD991;
		margin-top: 10px;
		span{
			font-weight: 400;
		}
	}
}