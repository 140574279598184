.rs-picker-daterange-menu {
  // left: 310px !important;
  right: unset !important;
  top: 275px !important;
  z-index: 99;
}

.rs-picker-daterange-header {
  display: none;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  background: transparent !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #0092ff;
  border-radius: 100px;
  border: 2px solid #0092ff;
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  padding-right: 32px;
}

.rs-picker-menu {
  box-shadow: 0px 2px 20px #0000001a !important;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  display: flex;
  color: #0092ff;
  position: relative;
  top: 0;
  left: 0;
  margin-right: 5px;
}

.rs-picker-toggle .rs-picker-toggle-placeholder,
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #0092ff;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #0092ff;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  border-color: #0092ff;
  background-color: #0092ff;
  border-radius: 30px;
}

.rs-calendar-table-cell-in-range::before {
  background-color: #e3f1fb;
}

.rs-calendar-table-cell:not(.rs-calendar-table-cell-selected):hover
  .rs-calendar-table-cell-content,
.rs-calendar-month-dropdown-cell:not(
    .rs-calendar-month-dropdown-cell-active
  ):hover
  .rs-calendar-month-dropdown-cell-content {
  background-color: #e3f1fb;
}

.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: #0092ff;
}

.rs-calendar-month-dropdown-year-active {
  color: #0092ff;
}

.rs-calendar-month-dropdown-cell-content {
  font-size: 12px;
  padding: 7px 7px 7px 5px;
}

.rs-calendar-table-cell-content {
  font-size: 12px;
  color: #1d2634;
  padding: 7px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  padding: 6px;
}

.rs-picker-toolbar-ranges {
  margin-left: 0px;
  margin-top: 0px;
}

.rs-picker-toolbar-option {
  color: #0092ff !important;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 20px;
  background: #e3f1fb;
  margin-right: 10px;
  border-radius: 100px;
}

.rs-picker-toolbar-option:not(:last-child)::before {
  display: none;
}

.rs-picker-toolbar-option:hover,
.rs-picker-toolbar-option:active,
.rs-picker-toolbar-option:focus {
  color: #0092ff;
  background: #e3f1fb;
}

.rs-picker-toolbar-option:active {
  border: 1px solid #0092ff;
}

.rs-picker-toolbar-right-btn-ok {
  background-color: #0092ff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 25px;
  padding: 6px 18px;
}

.rs-picker-toolbar-right-btn-ok:hover {
  background-color: #0092ff;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  background-color: #e3f1fb !important;
  border: 1px solid #e3f1fb !important;
  font-weight: 600;
  // padding: 8px 34px 7px 28px;
  padding: 0.6rem 1.25rem;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
  }
}
