.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
::placeholder {
  color: #999393;
  opacity: 0.8;
}
.emandateBtnContainer {
  display: flex;

  .virtualAccountbtn {
    padding: 0.5rem 1.3rem;
    border-radius: 20px;
    background-color: #0092ff;
    color: white;
    font-weight: 500;
    margin-left: auto;
  }
}
.virtualAccountOverlay {
  .virtualOverlayContentContainer {
    .close-popup-icon {
      right: -28px;
      top: -28px;
      position: absolute;
      z-index: 10000000;
      background: #f2f9ff 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: #0092ff;
      }
    }

    .emandate-modal-content {
      max-height: 80vh;
      overflow-y: auto;
      background-color: #fff;
      border-radius: 6px;
      max-width: 535px;
      position: relative;
      h4 {
        font-weight: 600;
        color: #43425d;
        align-self: flex-start;
        padding-left: 1rem;
        min-width: 500px;
        padding-right: 1rem;
        padding-bottom: 0.2rem;
        padding-top: 1rem;
        + div,
        + form {
          padding-top: 0rem !important;
        }
      }
      .d-flex {
        gap: 1rem;
      }
      .apply-button {
        padding: 8px 25px;
        border-radius: 18px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        background: #fff;
        border: 1px solid #ccc;

        &.colored {
          background: #0092ff;
          color: #ffffff;
          border: 1px solid #0092ff;
        }
        &.disabled-btn {
          border: 1px solid #f5f5f5;
          background-color: #f5f5f5;
          color: #d1d1d1;

          cursor: not-allowed;
        }
      }
    }
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    min-height: 100vh;
    z-index: 6;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    .virtualAccountWelcomePopUp {
      display: flex;
      flex-direction: column;
      padding: 1.25rem;
      min-width: 535px;
      padding-bottom: 0;
      h4 {
        font-weight: 600;
        color: #43425d;
        align-self: flex-start;
      }
      p {
        align-self: flex-start;
        color: #808495;
        font-size: 0.9rem;
      }
      
      .EmandateRegistrationinputContainer {
        .mandatory_input {
          color: red;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
        label {
          padding-right: 0.3rem;
          padding-left: 0.1rem;
          font-weight: 600;
          font-size: 0.9rem;
        }
        input {
          border: 1px solid rgba(0, 0, 0, 0.2);
          padding: 0.55rem 0.8rem;
          border-radius: 8px;
          font-weight: 400;
          font-size: 0.85rem;
          width: 60%;
          color: black;
          &:hover {
            border-color: #000;
          }
          &:focus {
            border-color: #0092ff;
          }
        }
      }
      .selectImageContainer {
        width: 100%;
        height: 8rem;
        margin-bottom: 0.5rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          max-width: 80%;
        }
        span {
          font-size: 3rem;
        }
      }
      input {
        width: 100%;
        border: 1px solid gray;
        border-radius: 6px;
        padding: 0.5rem;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
      .WelcomeToDecentroBtn {
        font-size: 1rem;
        width: 100%;
        color: black;
        margin: 0.5rem auto 0;
        padding: 0.4rem 0.2rem;
      }
    }
    .emandate__continue-btn {
      justify-content: flex-end;
      padding: 1.25rem;
      padding-top: 10px;

      padding-bottom: calc(1.5rem + 10px);
    }
    .EmandateRegistrationPopUp {
      min-width: 535px;
      padding: 1.35rem 1.25rem;
      border-radius: 6px;
      background-color: #fff;
      padding-bottom: 4px;
      .emandateRegistrationHeading {
        margin-bottom: 1rem;
        h4 {
          font-weight: 600;
          color: #43425d;
          margin-bottom: 0.2rem;
        }
        p {
          margin: 0.25rem 0 0.4rem;
          font-size: 0.9rem;
          font-weight: 600;
          color: #808495;
        }
      }
      .EmandateRegistrationSubmitBtn {
        display: flex;
        justify-content: flex-end;
        color: #878787;
        input {
          padding: 0.5rem 1.5rem;
          border-radius: 20px;
        }
      }
    }
    .text-input-wrapper {
      margin-bottom: 0.8rem !important;
    }
    .wallet-text-input {
      //   & width of input field
      width: 60%;
      border-radius: 10px;
      border: 1px solid #cbcbcb;
      input {
        width: 100% !important;
      }
      .issue-wallet-field-input {
        &::placeholder {
          text-transform: uppercase;
        }
        text-transform: uppercase;

        background-color: transparent;
        outline: none;
        border: 0;
        font-size: 14px;
        padding: 6px 16px;
        width: 100%;
        font: normal normal 400 14px/24px Montserrat;
        user-select: none;
        color: #000000;

        &::placeholder {
          font-weight: normal;
          color: #9f9f9f;
        }

        &:focus {
          outline: none;
        }

        &:-internal-autofill-selected {
          border-radius: 10px;
        }
      }

      // hide calendar icon
      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
    .EmandateRedirectPopUp {
      background-color: #fff;

      padding: 0.5rem;
      border-radius: 6px;
      min-width: 260px;
      padding: 1rem;
      margin-top: 1rem;
      text-align: center;
      a {
        font-weight: 400;
        &:hover {
          font-weight: 600;
        }
      }
    }

    .EmandateStatusPopUp {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 0.8rem 1rem;
      border-radius: 6px;
      min-width: 300px;

      .EmandateStatusHeading {
        padding-bottom: 0.3rem;
      }
      label {
        padding-bottom: 0.3rem;
        font-weight: 700;
        font-size: 0.9rem;
      }
      input {
        border: 1px solid gray;
        width: 100%;
        padding: 0.5rem;
        border-radius: 6px;
        margin-bottom: 0.8rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.EmandateRegistrationinputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  input {
    &:hover {
      border-color: #000;
    }
    &:focus {
      border-color: #0092ff;
    }
  }
  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    width: 50px;
  }
  .bank-option-menu {
    width: 60%;
    .provider-dropdown {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: relative;

      .provider-show {
        align-items: center;
        background: transparent;
        border: 1px solid #cbcbcb;
        border-radius: 10px;
        display: flex;
        font-size: 13px;
        font-weight: 500;
        justify-content: space-between;
        line-height: 20px;
        padding: 8px 12px;
        width: 100%;
        .provider-bank-option {
          background-color: #fff;
          display: none;
          position: absolute;
          right: 0;
          top: 30px;
          width: 100%;
          z-index: 50;
          .provider-options {
            background-color: #fff;
            margin-bottom: 5px;
          }
        }
        .active {
          border: 1px solid #0092ff;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top: 0;
          display: block;
        }
      }
      .show-border {
        border: 1px solid #0092ff;
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  label {
    padding-right: 0.3rem;
    font-weight: 600;
    font-size: 0.9rem;
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.55rem 0.8rem;
    border-radius: 8px;
    font-weight: 400;
    font-size: 0.85rem;
    width: 60%;
    color: black;
  }
}
.main_container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #0000000a;
  height: 100%;

  #example_info {
    padding-left: 24px;
  }

  .pad {
    padding-left: 35px;
  }

  .showpageinfo {
    padding: 24px 20px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 26px 20px;
  }

  thead > th > th.align-right {
    padding-left: 35px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    padding-top: 0;
    gap: 20px;

    .model {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
    .searchwrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      #loading1 {
        padding-right: 10px;
      }
    }
  }

  table.dataTable {
    margin: 0 auto;
    background-color: white;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      th:first-child {
        // padding-left: 35px;
      }

      th:last-child {
        // padding-right: 40px;
      }

      th.align-left {
        padding-left: 35px;
      }
    }

    tbody {
      tr {
        td:last-child {
          padding-right: 40px !important;
        }

        td {
          padding: 10px 20px;
        }
      }
    }
  }

  .dataTables_wrapper .dataTables_filter input {
    width: 280px;
    border-radius: 18px;
    padding: 7px;
    padding-left: 20px;
    border: 1px solid #cecfd0;
  }

  .dataTables_wrapper .dataTables_filter input::placeholder {
    font-size: 12px;
    color: rgb(46, 43, 43);
    text-align: left;
  }

  #selected3,
  #selected2 {
    width: 100px;
    position: absolute;
    background-color: white;
    line-height: 1.9;
    border-radius: 5px;
    grid-template-rows: auto auto auto auto;
    display: grid;
    justify-content: center;
    box-shadow: 0 2px 10px #0000001a;
    align-items: center;
    z-index: 1000;
  }

  #selected4 {
    width: 100px;
    position: absolute;
    background-color: white;
    line-height: 1.9;
    border-radius: 5px;
    grid-template-rows: auto auto auto auto;
    display: grid;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px #0000001a;

    /* flex-direction: column; */
    z-index: 1000;
  }

  #selected6 {
    width: 100px;
    position: absolute;
    background-color: white;
    line-height: 1.9;
    border-radius: 5px;
    grid-template-rows: auto auto auto auto;
    display: grid;
    box-shadow: 0 2px 10px #0000001a;

    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    z-index: 1000;
  }

  .rs-loader-md .rs-loader-spin,
  .rs-loader-md .rs-loader-spin:after,
  .rs-loader-md .rs-loader-spin:before {
    z-index: 1000 !important;
  }

  .rs-loader-backdrop {
    z-index: 1 !important;
  }

  .clear_icon {
    color: #0092ff;
    padding: 5px;
    display: flex;
    gap: 3px;
  }

  #Sorting0 {
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
  }

  #Sorting1 {
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
  }

  #Sorting2 {
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
  }

  .labelforfilter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    font-size: 11px;
    font-weight: 500;
    padding: 5px;
    color: #43425d;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }

  .show_entries {
    position: relative;
    #show_en {
      width: 175px;
      padding: 11px;
    }
    .new_show_entries {
      background: #fff;
      border-radius: 8px;
      // box-shadow: 0p 0 3px 4px gray;
      /* box-shadow: 0 0 15px -1px #bdbdbd; */
      box-sizing: border-box;
      /* color: #0092ff; */
      display: none;
      box-shadow: 0 2px 10px #0000001a;
      flex-direction: column;
      font-weight: 400;
      gap: 4px;
      height: 160px;
      left: 15px;
      position: absolute;
      top: 52px;
      width: 124px;
      z-index: 200;
      span {
        width: 100%;
        height: 40px;
        justify-content: space-around;
        background: #fff;
        padding: 5px;
        display: flex;
        // border-top-right-radius: 8px;
        // border-top-left-radius: 8px;
        border-bottom: 1px solid #e7e4e4;
        font-size: 12px;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #0092ff;
          color: white;
        }
      }
    }
  }

  .backbutton {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 0 !important;
    // padding: 26px 20px;
  }

  .scrollme {
    position: relative;
    overflow: auto;
    min-height: 475px;
  }

  .title {
    display: flex;
    font-size: 17px;
    font-weight: 600;
    gap: 12px;
    align-items: flex-end;
    justify-content: center;
  }

  #loadmore {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  // Changes made here
  #NewModel .va-details-container .right-qr-block {
    display: none;
  }

  #loadMoreButton {
    background: #e3f1fb;
    width: 80px;
    height: 25px;
    color: #0092ff;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  #loadbackbutton {
    background: #e3f1fb;
    width: 80px;
    height: 25px;
    color: #0092ff;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .clickToGoBack {
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
  }

  .customer_click {
    cursor: pointer;
  }

  th.customer_click {
    text-align: center;
  }

  th.dcn_urn_click {
    // text-align: left;
  }

  .kitnumber_click,
  .mobile-number-click,
  .wallet-number-click,
  .card-details-click {
    cursor: pointer;
  }

  .main_container .kitnumber_click a {
    color: #0092ff;
  }

  table.dataTable.display tbody tr.odd > .kitnumber_click a a:hover {
    color: #0092ff !important;
    text-decoration: none !important;
  }

  table.dataTable.display tbody tr.odd > .dcn_urn_click a a:hover {
    color: #0092ff !important;
    text-decoration: none !important;
  }

  table.dataTable.display tbody tr.even > .kitnumber_click a a:hover {
    color: #0092ff !important;
    text-decoration: none !important;
  }

  table.dataTable.display tbody tr.even > .dcn_urn_click a a:hover {
    color: #0092ff !important;
    text-decoration: none !important;
  }

  .clickToGoBack #backarrow {
    // transform: rotate(270deg);
    color: #0092ff;
    font-size: 16px;
    font-weight: 600;
    /* margin-bottom: 5px; */
    margin: 5px 5px 5px 5px;
  }

  #showAllAccounts {
    outline: none;
    border: none;
    background-color: white;
    font-weight: 600;
    color: #0092ff;
  }

  .buttons-csv {
    width: 90px;
    color: white;
    border-radius: 5px;
    background-color: #0092ff;
  }

  .success-column {
    margin: 10px;
    color: #71ba7b;
    font-weight: 800;
    text-align: center;
  }

  .pending-column {
    margin: 10px;
    color: #edd058;
    font-weight: 800;
    text-align: center;
  }

  .failure-column {
    margin: 10px;
    color: #d87b7b;
    font-weight: 800;
    text-align: center;
  }

  #refresh_icon {
    width: 18px;
    padding: 3px;
  }

  #DataTables_Table_0_wrapper .dt-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;

    .va_buttons-colvis {
      height: 35px;
      font-weight: 600;
      width: 140px;
      background: #e3f1fb !important;
      font-size: 12px;
      text-transform: capitalize;
      color: #0092ff;
      border-radius: 18px;
    }

    .buttons-copy {
      left: 10%;
      width: 90px;
      color: white;
      border-radius: 5px;
      background-color: #0092ff;
    }

    #button_container {
      width: 100px;
      height: 50px;
      z-index: 1000;
    }
  }

  .loading {
    width: 100%;
  }

  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: bold;
  }

  table.dataTable thead th,
  table.dataTable thead td {
    // padding: 10px 18px;
    text-transform: capitalize;
    font-size: 12px;
    background-color: #f5f6fa;
    padding: 10px 20px;
  }

  table.dataTable thead th:active,
  table.dataTable thead td:active {
    outline: none;
  }

  table.dataTable tfoot th,
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111;
  }

  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc {
    cursor: pointer;
    *cursor: hand;
  }

  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc,
  table.dataTable thead .sorting_asc_disabled,
  table.dataTable thead .sorting_desc_disabled {
    background-repeat: no-repeat;
    background-position: center right;
  }

  table.dataTable tbody tr {
    background-color: white;
    text-align: center;
    font-size: 12px;
    height: 60px;
  }

  table.dataTable tbody tr.selected {
    background-color: #b0bed9;
  }

  .next-icon {
    display: flex;
  }

  table.dataTable tbody th,
  table.dataTable tbody td {
    // padding: 8px 10px;
    padding: 10px 20px !important;
  }

  table.dataTable.row-border tbody th,
  table.dataTable.row-border tbody td,
  table.dataTable.display tbody th,
  table.dataTable.display tbody td {
    border-top: 1px solid #dddddd;
  }

  table.dataTable.row-border tbody tr:first-child th,
  table.dataTable.row-border tbody tr:first-child td,
  table.dataTable.display tbody tr:first-child th,
  table.dataTable.display tbody tr:first-child td {
    border-top: none;
  }

  table.dataTable.cell-border tbody th,
  table.dataTable.cell-border tbody td {
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  table.dataTable.cell-border tbody tr th:first-child,
  table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #dddddd;
  }

  table.dataTable.cell-border tbody tr:first-child th,
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none;
  }

  table.dataTable.stripe tbody tr.odd,
  table.dataTable.display tbody tr.odd {
    background-color: white;
    text-align: center;
  }

  table.dataTable.display tbody tr.odd td,
  table.dataTable.display tbody tr.even td {
    padding: 10px 20px 10px 20px !important;
  }

  table.dataTable.stripe tbody tr.odd.selected,
  table.dataTable.display tbody tr.odd.selected {
    background-color: #abb9d3;
  }

  table.dataTable.hover tbody tr:hover,
  table.dataTable.display tbody tr:hover {
    background-color: white;
  }

  table.dataTable.hover tbody tr:hover.selected,
  table.dataTable.display tbody tr:hover.selected {
    background-color: #fff;
  }

  table.dataTable.order-column tbody tr > .sorting_1,
  table.dataTable.order-column tbody tr > .sorting_2,
  table.dataTable.order-column tbody tr > .sorting_3,
  table.dataTable.display tbody tr > .sorting_1,
  table.dataTable.display tbody tr > .sorting_2,
  table.dataTable.display tbody tr > .sorting_3 {
    background-color: white;
  }

  table.dataTable.order-column tbody tr.selected > .sorting_1,
  table.dataTable.order-column tbody tr.selected > .sorting_2,
  table.dataTable.order-column tbody tr.selected > .sorting_3,
  table.dataTable.display tbody tr.selected > .sorting_1,
  table.dataTable.display tbody tr.selected > .sorting_2,
  table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #acbad4;
  }

  table.dataTable.display tbody tr.odd > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: white;
    // padding: 10px 20px!important;
    padding: 10px 20px 10px 20px !important;
  }

  table.dataTable.display tbody tr.odd > .sorting_1 a {
    color: #0092ff !important;
  }

  table.dataTable.display tbody tr.even > .kitnumber_click a {
    color: #0092ff !important;
  }

  table.dataTable.display tbody tr.odd > .bank_ref_click a {
    color: #0092ff !important;
  }

  table.dataTable.display tbody tr.odd > .dcn_urn_click a {
    color: #0092ff !important;
  }

  table.dataTable.display tbody tr.odd > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #f3f3f3;
  }

  table.dataTable.display tbody tr.odd > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke;
  }

  table.dataTable.display tbody tr.odd.selected > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b3cd;
  }

  table.dataTable.display tbody tr.odd.selected > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a7b5ce;
  }

  table.dataTable.display tbody tr.odd.selected > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b6d0;
  }

  table.dataTable.display tbody tr.even > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: white;
    // padding: 10px 20px!important;
    padding: 10px 20px 10px 20px !important;
  }

  table.dataTable.display tbody tr.even > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fbfbfb;
  }

  table.dataTable.display tbody tr.even > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fdfdfd;
  }

  table.dataTable.display tbody tr.even.selected > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad4;
  }

  table.dataTable.display tbody tr.even.selected > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #adbbd6;
  }

  table.dataTable.display tbody tr.even.selected > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8;
  }

  table.dataTable.display tbody tr:hover > .sorting_1,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #fff;
  }

  table.dataTable.display tbody tr:hover > .sorting_2,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #fff;
  }

  table.dataTable.display tbody tr:hover > .sorting_3,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #fff;
  }

  table.dataTable.display tbody tr:hover.selected > .sorting_1,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #fff;
  }

  table.dataTable.display tbody tr:hover.selected > .sorting_2,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #fff;
  }

  table.dataTable.display tbody tr:hover.selected > .sorting_3,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #fff;
  }

  //changes here
  .rs-loader-spin {
    color: #0092ff;
  }

  .dt-button-background {
    display: none !important;
  }

  .align-right {
    // text-align: right!important;
  }

  table.dataTable tbody th:last-child {
    // text-align: right!important;
  }

  .rs-loader-spin::after {
    border-width: 3px;
    border-style: solid;
    border-color: #0092ff transparent transparent !important;
    -webkit-animation: loaderSpin 0.6s infinite linear;
    animation: loaderSpin 0.6s infinite linear;
  }

  table.dataTable.no-footer {
    border-bottom: none;
    border-radius: 10px;
    // line-height: 1.6;
    font-size: 12px;
    font-weight: 500;
  }

  table.dataTable.no-footer tbody {
    border-radius: 10px;
    padding: 15px;
  }

  table.dataTable.nowrap th,
  table.dataTable.nowrap td {
    white-space: nowrap;
  }

  table.dataTable.compact thead th,
  table.dataTable.compact thead td {
    padding: 4px 17px 4px 4px;
  }

  table.dataTable.compact tfoot th,
  table.dataTable.compact tfoot td {
    padding: 4px;
  }

  table.dataTable.compact tbody th,
  table.dataTable.compact tbody td {
    padding: 4px;
  }

  table.dataTable th.dt-left,
  table.dataTable td.dt-left {
    text-align: left;
  }

  table.dataTable th.dt-center,
  table.dataTable td.dt-center,
  table.dataTable td.dataTables_empty {
    text-align: center;
  }

  table.dataTable th.dt-right,
  table.dataTable td.dt-right {
    text-align: right;
  }

  table.dataTable th.dt-justify,
  table.dataTable td.dt-justify {
    text-align: justify;
  }

  table.dataTable th.dt-nowrap,
  table.dataTable td.dt-nowrap {
    white-space: nowrap;
  }

  table.dataTable thead th.dt-head-left,
  table.dataTable thead td.dt-head-left,
  table.dataTable tfoot th.dt-head-left,
  table.dataTable tfoot td.dt-head-left {
    text-align: left;
  }

  table.dataTable thead th.dt-head-center,
  table.dataTable thead td.dt-head-center,
  table.dataTable tfoot th.dt-head-center,
  table.dataTable tfoot td.dt-head-center {
    text-align: center;
  }

  table.dataTable thead th.dt-head-right,
  table.dataTable thead td.dt-head-right,
  table.dataTable tfoot th.dt-head-right,
  table.dataTable tfoot td.dt-head-right {
    text-align: right;
  }

  table.dataTable thead th.dt-head-justify,
  table.dataTable thead td.dt-head-justify,
  table.dataTable tfoot th.dt-head-justify,
  table.dataTable tfoot td.dt-head-justify {
    text-align: justify;
  }

  table.dataTable thead th.dt-head-nowrap,
  table.dataTable thead td.dt-head-nowrap,
  table.dataTable tfoot th.dt-head-nowrap,
  table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap;
  }

  table.dataTable tbody th.dt-body-left,
  table.dataTable tbody td.dt-body-left {
    text-align: left;
  }

  table.dataTable tbody th.dt-body-center,
  table.dataTable tbody td.dt-body-center {
    text-align: center;
  }

  table.dataTable tbody th.dt-body-right,
  table.dataTable tbody td.dt-body-right {
    text-align: right;
  }

  table.dataTable tbody th.dt-body-justify,
  table.dataTable tbody td.dt-body-justify {
    text-align: justify;
  }

  table.dataTable tbody th.dt-body-nowrap,
  table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap;
  }

  table.dataTable,
  table.dataTable th,
  table.dataTable td {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  /*
     * Control feature layout
     */
  .dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;
    zoom: 1;
  }

  .dataTables_wrapper .dataTables_length {
    // float: left;
    // width: 145px;
    font-size: 12px;
    padding: 11px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-weight: 600;
    font-family: Montserrat !important;
  }

  #manage_box {
    display: flex;
    width: 220px;
    justify-content: center;
    flex-direction: column;
  }

  #menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .label_ma {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right;
  }

  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
  }

  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    background: white !important;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #333333 !important;
    border: 1px solid transparent;
    border-radius: 2px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    // color: #0092ff !important;
    border: 1px solid #0092ff;
    border-radius: 30px;
    background-color: white;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, white),
      color-stop(100%, gainsboro)
    );
    /* Chrome,Safari4+ */
    // background: -webkit-linear-gradient(top, white 0%, gainsboro 100%);
    /* Chrome10+,Safari5.1+ */
    // background: -moz-linear-gradient(top, white 0%, gainsboro 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, white 0%, gainsboro 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, white 0%, gainsboro 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, white 0%, gainsboro 100%);
    /* W3C */
  }

  .dataTables_wrapper .dataTables_length {
    padding: 10px 24px;
  }

  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    margin: 5px;
    color: #333333;
    padding: 8px;
  }

  table.dataTable thead .sorting {
    background-color: #f5f6fa;
    background: #f5f6fa no-repeat center right;
    width: 40px;
    height: 40px;
  }

  table.dataTable thead .sorting_asc {
    background-color: #f5f6fa;
    background: #f5f6fa no-repeat right;
    width: 40px;
    height: 40px;
  }

  table.dataTable thead .sorting_desc {
    background-color: #f5f6fa;
    background: #f5f6fa no-repeat center right;
    background-size: 15px;
    width: 40px;
    height: 40px;
  }

  table.dataTable thead th:first-child,
  table.dataTable thead th:first-child {
    // text-align: left!important;
  }

  table.dataTable thead .sorting_asc_disabled {
    background-color: #f5f6fa;
    background: #f5f6fa no-repeat center right;
    background-size: 15px;
    width: 40px;
    height: 40px;
  }

  table.dataTable thead .sorting_desc_disabled {
    background-color: #f5f6fa;
    background: #f5f6fa no-repeat center right;
    background-size: 15px;
    width: 40px;
    height: 40px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 1px solid transparent;
    background: white !important;
    box-shadow: none;
  }

  #example_paginate {
    display: flex;
    align-items: center;
  }

  .clear_filter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    padding: 5px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    // border: 1px solid #111111;
    border-radius: 50%;
    background-color: #0092ff !important;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #585858),
      color-stop(100%, #111111)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #585858 0%, #111111 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, #585858 0%, #111111 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, #585858 0%, #111111 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, #585858 0%, #111111 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #585858 0%, #111111 100%);
    /* W3C */
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    background-color: #0092ff !important;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #2b2b2b),
      color-stop(100%, #0c0c0c)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
    /* W3C */
    box-shadow: inset 0 0 3px #111;
  }

  .dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 1em;
  }

  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(25%, rgba(255, 255, 255, 0.9)),
      color-stop(75%, rgba(255, 255, 255, 0.9)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #333333;
  }

  .dataTables_wrapper .dataTables_length {
    padding: 10px 28px !important;
  }

  .dataTables_wrapper .dataTables_scroll {
    clear: both;
  }

  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
  }

  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th,
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td {
    vertical-align: middle;
  }

  .dataTables_wrapper
    .dataTables_scroll
    div.dataTables_scrollBody
    th
    > div.dataTables_sizing,
  .dataTables_wrapper
    .dataTables_scroll
    div.dataTables_scrollBody
    td
    > div.dataTables_sizing {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
  }

  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111111;
  }

  .dataTables_wrapper.no-footer div.dataTables_scrollHead table,
  .dataTables_wrapper.no-footer div.dataTables_scrollBody table {
    border-bottom: none;
  }

  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }

  div.date {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dataTables_length {
    background-color: #e3f1fb;
    color: #0092ff;
    border-radius: 30px;
  }

  .dataTables_length label {
    position: relative;
    color: #0092ff;
    font-weight: 600;
    font-size: 11px;
    padding-left: 5px;
  }

  .bank_ref_click {
    cursor: pointer;
  }

  .dcn_urn_click {
    cursor: pointer;
  }

  .table.dataTable thead th,
  table.dataTable thead td {
    padding: 10px 18px;
    text-transform: capitalize;
    // border-bottom: 1px solid #d2d3d4;
    /* border-top: 1px solid #d2d3d4; */
    background-color: #f5f6fa;
  }

  .dataTables_length select {
    background-color: #e3f1fb;
    color: #0092ff;
    border: none;
    font-size: 12px;
    // right: -49px;
    // top: -1px;
    // position: absolute;
  }

  #datecont > .rs-picker-daterange-menu {
    left: 349px !important;
    right: unset !important;
    top: 221px !important;
    z-index: 99;
  }

  .dataTables_length select :hover {
    background: white;
  }

  .dataTables_length select option {
    background: #ffffff !important;
  }

  // Changes done here
  #datecont > .rs-picker-default .rs-picker-toggle.rs-btn {
    background-color: #e3f1fb !important;
    border: 1px solid #e3f1fb !important;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 8px 34px 8px 32px;
  }

  .dt-button-background {
    display: none;
  }

  .rs-picker-daterange .rs-picker-toggle-caret::before {
    line-height: 18px;
    margin-right: 4px;
  }

  .dt-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  #limitcount {
    display: flex;
    gap: 8px;
  }

  .labelcheck {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .labelcheck > input {
    width: 20px;
    height: 20px;
  }

  div.dt-button-collection {
    position: absolute;
    top: 133px !important;
    left: 0;
    width: 200px;
    // height: 250px;
    overflow-y: scroll;
    overflow: scroll;
    margin-top: 3px;
    padding: 10px;
    // border: 1px solid #ccc;
    // border: 1px solid rgba( 0, 0, 0, 0.4 );
    background-color: white;
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #0000001a;

    button.dt-button,
    div.dt-button,
    a.dt-button {
      position: relative;
      left: 0;
      color: white;
      border-radius: 5px;
      background: #0092ff;
      right: 0;
      text-transform: capitalize;
      height: 30px;
      text-align: left;
      font-size: 12px;
      width: 100%;
      display: block;
      float: none;
      margin-bottom: 4px;
    }
  }

  div.dt-button-collection button.dt-button,
  div.dt-button-collection div.dt-button,
  div.dt-button-collection a.dt-button {
    position: relative;
    left: 0;
    color: white;
    border-radius: 5px;
    background: white;
    right: 0;
    border-bottom: 1px solid #f1f1f1;
    color: rgb(56, 56, 56);
    text-transform: capitalize;
    height: 30px;
    text-align: left;
    font-size: 12px;
    width: 100%;
    display: block;
    float: none;
    margin-bottom: 4px;
  }

  #colvis .buttons-columnVisibility:before,
  #colvis .buttons-columnVisibility.active span:before {
    display: block;
    position: absolute;
    top: 1.2em;
    color: #0092ff;
    left: 144px;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
  }

  .button_container {
    background: #e3f1fb;
    border-radius: 18px;
    color: #0092ff;
    font-weight: 600;
    text-align: center;
    width: max-content;
    padding: 10px 24px;
    font-size: 12px;
  }

  .dt-btn-split-wrapper {
    background: #e3f1fb;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dt-btn-split-drop.dt-button {
    color: #0092ff;
    margin-right: 6px;
    background-color: #e3f1fb;
  }

  //Arrow Changes here
  .dt-down-arrow {
    // margin: 4px;
    // font-size: 10px;
    display: none;
  }

  button.dt-button span.dt-down-arrow,
  div.dt-button span.dt-down-arrow,
  a.dt-button span.dt-down-arrow,
  input.dt-button span.dt-down-arrow {
    // position: relative;
    // top: -4px;
    // color: rgba(70, 70, 70, 0.75);
    // font-size: 8px;
    // padding-left: 10px;
    // line-height: 1em;
  }

  .dt-button-collection .dt-button-split-left .dtb-b1 {
    width: 80px;
    height: 60px;
  }

  .dt-btn-split-drop-arrow {
    color: #0092ff;
    background-color: #e3f1fb;
    font-size: 11px;
  }

  #colvis .buttons-columnVisibility:before {
    content: " ";
    margin-top: -6px;
    margin-left: 10px;
    border: 1px solid #0092ff;
    border-radius: 3px;
  }

  #colvis .buttons-columnVisibility.active span:before {
    content: "✔";
    margin-top: -11px;
    margin-left: 12px;
    text-align: center;
    // text-shadow: 1px 1px #ddd, -1px -1px #ddd, 1px -1px #ddd, -1px 1px #ddd;
  }

  #colvis .buttons-columnVisibility span {
    margin-left: 20px;
  }

  .buttons-colvis {
    font-weight: 600;
    background: #e3f1fb !important;
    color: #0092ff;
    font-size: 12px;
    text-transform: capitalize;
    padding: 9px 25px 9px 28px;
    border-radius: 18px;
  }

  //container for headers to show filters
  .cb-dropdown-wrap {
    min-height: 23px;
    max-height: 23px;
    transition: max-height 0.2s ease;
    overflow-y: auto;
    border: 1px solid #888;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    margin-top: 5px;
  }

  //made changer here loader
  .rs-loader-wrapper {
    color: #0092ff !important;
  }

  .cb-dropdown-wrap:hover {
    height: auto;
    max-height: 80px;
    /* At most, around 3/4 visible items. */
  }

  .cb-dropdown,
  .cb-dropdown li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .cb-dropdown li label {
    padding: 3px 0;
    display: block;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }

  .cb-dropdown li label > input {
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
  }

  .cb-dropdown li label > span {
    display: block;
    margin-left: 3px;
    margin-right: 20px;
    /* At least, width of the checkbox. */
    font-family: sans-serif;
    font-size: 0.8em;
    font-weight: normal;
    text-align: left;
  }

  .factive {
    background-color: #c9e2c6;
  }

  #issuedCardsTable th {
    // background-position: 85% 8px;
    // min-width: 100px;
  }
}

//containers done

// header arrow changes
th img {
  // margin-left: 8px !important;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}

@media only screen and (max-width: 1150px) {
  .main_container {
    .wrapper .model {
      // background-color: red;
      display: grid;
      align-items: center;
      grid-template-rows: 1fr 1fr;
      justify-content: center;
      gap: 5px;
      grid-template-columns: 200px 200px;

      .buttons-colvis {
        width: 100%;
      }

      .button_container {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}

@media only screen and (max-width: 1150px) {
  .main_container {
    // .tran-wrapper .tran-model {
    //   // background-color: red;
    //   display: grid;
    //   align-items: center;
    //   grid-template-rows: 1fr 1fr;
    //   justify-content: center;
    //   gap: 20px;
    //   grid-template-columns: 200px 200px;
    //   .buttons-colvis {
    //     width: 100%;
    //   }
    //   .button_container {
    //     width: 100%;
    //   }
    // }
    //   .wrapper {
    //     align-items: center;
    //     gap: 0px;
    //     flex-wrap: wrap;
    //     display: flex;
    //     justify-content: flex-start;
    //     padding: 0 20px 20px;
    //     .main_container div.date {
    //       align-items: center;
    //       justify-content: space-between;

    //     }
    //   }
    .dataTables_wrapper .dataTables_filter {
      float: right;
      flex-basis: fit-content;
      text-align: right;
      /* flex: 1; */
      // margin-right: 20px;
    }
    .dataTables_wrapper .dataTables_filter input {
      border: 1px solid #cecfd0;
      border-radius: 18px;
      padding: 9px 9px 7px 20px;
      width: 170px;
    }
    #DateContainer {
      align-items: center;
      flex-basis: fit-content;
      justify-content: space-between;
      // margin-right: 8px;

      #datecont {
        width: 70px;
        // margin-right: 20px;
      }
    }

    .show_entries {
      margin-right: 0px;
      flex-basis: fit-content;
      position: relative;
      #show_en {
        width: 150px;
        padding: 10px 22px;
      }
      /* flex: 1; */
    }

    #colvis {
      // margin-right: 20px;
      /* flex: 1; */
      flex-basis: fit-content;
      .main_container .buttons-colvis {
        // margin-right: 8px;
      }
    }
    #download {
      flex: 1;
    }
  }

  // .main_container_transaction {
  //   #tran-searchwrapper {
  //     width: 230px;
  //   }
  // }

  // .dataTables_filter{
  //   .search__submit {
  //     top: 2px;
  //   background: 0;
  //   border: 0;
  //   position: absolute;
  //   left: 10px;
  //   border-radius: 50%;
  //   color: var(--clr-body);
  //   cursor: pointer;
  //   font-size: 1.5rem;
  //   height: calc(var(--size) - 10px);
  //   margin-left: auto;
  //   display: block;
  //   transition: background .2s ease-out;
  //   width: calc(var(--size) - 10px);
  //   img{
  //     width: 20px;
  //   }
  //   }
  // }

  // .main_container_transaction .dataTables_wrapper .dataTables_filter input {
  //   background: transparent;
  //   border: 0;
  //   bottom: 0;
  //   cursor: pointer;
  //   flex-grow: 1;
  //   left: 19px;
  //   opacity: 0;
  //   outline: 0;
  //   padding: 0.25em 1em;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  //   z-index: 2;
  // }
  // .main_container_transaction .dataTables_wrapper .dataTables_filter {
  //   position: relative;
  //   align-items: end;

  //   width: 230px;
  //   display: flex;
  //   justify-content: flex-start;
  // }
  // .main_container_transaction .dataTables_wrapper .dataTables_filter label {
  //   --size: 28px;
  //   border: 1px solid #cecfd0;
  //   border-radius: 100px;
  //   display: flex;
  //   font-size: 1.25em;
  //   height: 40px;
  //   overflow: hidden;
  //   padding: 3px;
  //   position: relative;
  //   transition: width .45s cubic-bezier(.18,.89,.32,1.28);
  //   width: 40px;
  // }
  // .main_container_transaction
  //   .dataTables_wrapper
  //   .dataTables_filter
  //   label:focus-within {
  //     width: 220px;
  //    input {
  //     cursor: auto;
  //   opacity: 1;
  //   width: calc(100% - 17px);
  //   z-index: auto;

  //   }

  //   .dataTables_filter .search__submit {
  //       // background: #0092ff;
  //       // color: white;
  //       // box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  //       left: 178px;
  //       transition: left 150ms ease-in-out;
  //     }

  // }

  // .main_container_transaction .dataTables_wrapper .dataTables_filter input:focus,
  // .main_container_transaction .dataTables_wrapper .dataTables_filter input:not(:placeholder-shown) {
  //   width: 180px;
  //   padding: 0 6px;
  // }
  // .main_container_transaction .dataTables_wrapper .dataTables_filter label:hover{
  //   width: 200px;
  //   justify-content: center;
  //   display: flex;
  //   align-items: center;
  // }
  // .main_container_transaction .dataTables_wrapper .dataTables_filter label:hover > input {
  //   width: 180px;
  //   padding: 0 6px;
  // }
  // .search-box:hover > .search-btn,
  // .search-input:focus + .search-btn,
  // .search-input:not(:placeholder-shown) + .search-btn {
  //   background: #fff;
  //   color: #cd595a;
  // }
  .main_container div.date {
    // margin-right: 8px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  // .main_container_va .wrapper {
  //   display: flex;
  //   flex: 1;
  //   flex-flow: row;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 20px;
  //   padding-top: 0;
  // }
  .main_co .show_entries #show_en {
    width: 112px;
    padding: 10px 15px;
  }
  .main_container .buttons-colvis {
    width: 143px;
    padding: 10px 15px;
  }

  .main_container .wrapper .model {
    display: grid;
    align-items: center;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
  }

  .main_container #datecont > .rs-picker-default .rs-picker-toggle.rs-btn {
    background-color: #e3f1fb !important;
    border: 1px solid #e3f1fb !important;
    font-weight: 600;
    padding: 6px 15px;
    font-size: 10px;
    padding-right: 36px;
  }

  .main_container .dataTables_wrapper .dataTables_length {
    flex: 1;
    padding: 8px 15px;
  }

  .main_container .dataTables_wrapper .dataTables_length {
    font-size: 10px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 2px; */
    font-weight: 600;
    font-family: Montserrat !important;
  }

  .main_container .dataTables_length select {
    background-color: #e3f1fb;
    color: #0092ff;
    border: none;
    font-size: 10px;
  }

  .main_container .wrapper .model .buttons-colvis {
    width: 100%;
  }

  .main_container .buttons-colvis {
    font-weight: 600;
    background: #e3f1fb !important;
    color: #0092ff;
    font-size: 10px;
    text-transform: capitalize;
    padding: 6px 15px;
    border-radius: 18px;
  }

  .main_container .wrapper .model .button_container {
    width: 100%;
  }

  .main_container .button_container {
    background: #e3f1fb;
    border-radius: 18px;
    color: #0092ff;
    font-weight: 600;
    text-align: center;
    width: max-content;
    padding: 8px 15px;
    font-size: 10px;
  }

  .main_container .dataTables_wrapper .dataTables_filter input {
    width: 170px;
    border-radius: 18px;
    padding: 7px;
    padding-left: 20px;
    border: 1px solid #cecfd0;
  }

  .main_container table.dataTable thead th,
  .main_container table.dataTable thead td {
    padding: 8px 15px;
    text-transform: capitalize;
    font-size: 10px;
    background-color: #f5f6fa;
  }

  .main_container table.dataTable tbody tr {
    background-color: white;
    text-align: center;
    font-size: 10px;
    height: 57px;
  }
}

input.err {
  border-color: red !important;
}

.error-message {
  width: 100%;
  background: #fdf3f3;
  color: #e06060 !important;
  text-align: center;
  border-radius: 0.4rem;
  margin-top: 2rem;
  padding: 1rem;
  font-size: 0.8rem !important;
  font-weight: 600;
}

