.callback-container {
  .add-callback {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      img {
        width: 1.2rem;
      }
      h1.heading {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 30px;
        color: #43425d;
        display: flex;
        align-items: center;
      }
    }
  }
  .callback-divider {
    border-top: 1px solid #cbcbcb;
    margin: 2rem 0;
  }
}
.callback-input-container {
  display: flex;
}
.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  .input-group__upper {
    flex: 1;
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  .input-group__lower {
    flex: 1;
    display: flex;
    gap: 2rem;
    margin-bottom: 2.2rem;
  }
}
// Normal Input callback field .
.text-input-wrapper {
  width: 100%;
  .text-input-label {
    font-size: 1rem;
    font-weight: 500;
    color: #43425d;
    user-select: none;
    margin-bottom: 8px;
    &::after {
      content: " *";
      color: red;
    }
  }
  #not-mandatory::after {
    content: "";
  }
  .label-wrapper {
    display: flex;
    align-items: baseline;
    // gap: 0.5rem;
    img {
      width: 1rem;
      color: #0092ff;
    }
  }
}
.add-callback-input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #cbcbcb;
  .callback-field-input {
    background-color: transparent;
    outline: none;
    border: 0;
    font-size: 14px;
    padding: 8px 18px;
    width: 100%;
    font: normal normal 400 14px/24px Montserrat;
    user-select: none;
    color: #000000;
    &::placeholder {
      font-weight: normal;
      color: #9f9f9f;
    }
    &:focus {
      outline: none;
    }
  }
}
.error-border {
  border: 1px solid red;
}
// BTN CSS
.new-callback-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border: none;
  outline: none;
  padding: 6px 30px;
  border-radius: 100px;
  background: #0092ff;
  color: #ffffff;
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
  span {
    font-size: 1.4rem;
  }
}
.edit-list-heading {
  font-size: 1.125rem;
  font-weight: 400;
  padding: 2rem 0;
  color: #43425d;
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}
.button-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1.8rem 2rem;
  padding-right: 0;
  .submit-callback-details {
    flex: 1;
    border: none;
    outline: none;
    padding: 12px 42px;
    border-radius: 100px;
    background: #0092ff;
    color: #ffffff;
    opacity: 0.5;
    font-size: 13px;
    font-weight: 500;
  }
  .active-callback-btn {
    opacity: 1;
  }
}
