.manage-ip-container {
  display: flex;
  align-items: center;
  justify-content: center;

  p.action-alert-text {
    font-size: 0.725rem;
    color: #a2a2a2;
    margin-right: 0.8rem;
    a{
        color: #0092ff;
        font-weight: 600;
    }
  }

  .manage-ips-btn {
    align-items: center;
    background: #0092ff;
    border: none;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 6px;
    justify-content: center;
    margin: 0;
    outline: none;
    padding: 8px 20px;
  }
}
