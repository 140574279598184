.CkycSearch {
    background-color: #fff;
    border-radius: 10px;
    .total-count {
      display: flex;
      justify-content: space-between;
      margin: 30px 0px 20px;
      padding: 0 1.5rem;
      .entriesFoundContainer {
        padding-left: 0.5rem;
      }
      .title {
        font-size: 17px !important;
        font-weight: 600;
        span:not(.text-primary) {
          color: #a2a2a2 !important;
          font-size: 12px !important;
          font-weight: 400 !important;
        }
      }
      .virtual-accounts-btn {
        align-items: center;
        background: #0092ff;
        border: none;
        border-radius: 20px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        gap: 6px;
        justify-content: center;
        margin: 0;
        outline: none;
        padding: 8px 20px;
      }
    }
  }
  .filter-data-ckyc {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 1.2rem;
    .search-filter {
      width: 17%;
      background-color: #fff !important;
      border: 1px solid #cecfd0 !important;
      font-weight: 200;
      padding: 0.4rem 1.25rem;
      border-radius: 100px;
      input {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
    .download-csv {
      width: 12%;
      button {
        background-color: #e3f1fb !important;
        border: 1px solid #e3f1fb !important;
        font-weight: 600;
        padding: 0.60rem 1.25rem;
        border-radius: 100px;
        color: #0092ff;
        font-size: 12px;
        width: 100%;
      }
    }
  }
  
  .show-entries-wrapper {
    position: relative;
    background: #fff;
    z-index: 10;
    width: 14%;
    .entries-wrapper {
      button {
        background-color: #e3f1fb!important;
      border: 1px solid #e3f1fb!important;
      border-radius: 100px;
      color: #0092ff;
      font-weight: 600;
      font-size: 12px;
      padding: 0.66rem 1.25rem;
      width: 100%;
      }
  
      .block {
        display: block;
        position: absolute;
        width: 220px;
        top: 10;
        top: 10;
        right: -24px;
        padding: 15px;
        background-color: white !important;
        border-radius: 10px;
        box-shadow: 0 2px 10px #0000001a;
      }
      .hidden {
        display: none;
      }
      ul {
        width: 100%;
        padding: 5px;
        li {
          padding: 10px 10px 10px 15px;
          font-size: 13px;
          cursor: pointer;
          border-bottom: 1px solid #cac3c3;
          &:last-child {
            border-bottom: none;
          }
          &:hover,
          &:focus,
          &:active {
            background-color: #0092ff;
            color: white;
          }
        }
      }
    }
  }
  .show-column-wrapper {
    position: relative;
    z-index: 10;
    width: 14%;
    .column-wrapper {
      button {
        background-color: #e3f1fb !important;
        border: 1px solid #e3f1fb !important;
        font-weight: 600;
        padding: 0.60rem 1.25rem;
        border-radius: 100px;
        color: #0092ff;
        font-size: 12px;
        width: 100%;
      }
      .checkboxIcon {
        height: 0.75rem;
        width: 0.75rem;
        border: 1.5px solid #0092ff;
        border-radius: 2px;
        position: relative;
        .checkIcon {
          position: absolute;
          left: 0.1rem;
          top: -0.4rem;
          font-size: 0.8rem;
          color: #0092ff;
        }
      }
      .block {
        display: block;
        position: absolute;
        width: 236px;
        padding: 20px;
        background-color: white !important;
        border-radius: 10px;
        box-shadow: 0 2px 10px #0000001a;
      }
      .hidden {
        display: none;
      }
      ul {
        width: 100%;
        padding: 5px;
        li {
          > label {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          padding: 13px 13px 13px 20px;
          cursor: pointer;
          border-bottom: 1px solid rgb(202, 195, 195);
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  .csvModal {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    .content-modal {
      text-align: center;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ccc;
      width: 40%;
      min-width: 400px;
      padding: 1.5rem;
      p {
        margin: 2rem 0;
      }
      .modal-btns {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
    }
  }