.ui-file-download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e5f4ff;
    border: none;
    border-radius: 30px;
    color: #0092ff;
    font-size: 13px;
    font-weight: 600;
    opacity: 1;
    outline: none;
    padding: 10px 20px;
    line-height: 18px;

  }