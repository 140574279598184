.manage-beneficiary-main-card-container {
  width: 100%;
  height: 550px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  display: flex;

  .hide {
    visibility: hidden;
  }
  .in_not {
    position: absolute;
    right: 10px;
    height: 15px;
    top: 14px;
  }
  .show {
    visibility: visible;
  }

  .company-search {
    width: 100%;
    display: flex;
    align-items: center;
    height: 12%;
    margin-bottom: 8px;
    .user-search-input-label {
      font-size: 14px;
      font-weight: 500;
      width: 32%;
      color: #43425d;
      -webkit-user-select: none;
      -ms-user-select: none;
      align-items: center;
      user-select: none;
      // margin-bottom: 2.1rem;
      display: flex;
      margin-right: 8px;

      &::after {
        content: " *";
        color: red;
      }
    }
    .user-search-input-label-not-required {
      font-size: 14px;
      font-weight: 500;
      width: 32%;
      color: #43425d;
      -webkit-user-select: none;
      -ms-user-select: none;
      align-items: center;
      user-select: none;
      // margin-bottom: 2.1rem;
      display: flex;
      margin-right: 8px;
    }
  }
  .company {
    width: 100%;
    display: flex;
    flex: 1 1;
    .provider-dropdown-cv {
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 57%;
      cursor: pointer;
      .provider-show-cv {
        width: 100%;
        padding: 10px 18px;
        border-radius: 10px;
        background: transparent;
        border: 1px solid #cbcbcb;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        // justify-content: center;
        svg,
        img {
          width: 20px;
          height: 20px;
          margin-left: auto;
          color: #848484;
        }
        &.show-border-cv {
          border: 1px solid #0092ff;
        }
        &.show-border-cv:hover {
          border: 1px solid #0092ff;
        }
      }
      .provider-show-cv:hover {
        outline: none;
        border: 1px solid #000000;
      }
      .placeholder-text-cv {
        font-weight: 400;
        color: #9f9f9f;
        font-size: 14px;
        padding-left: 2px;
      }
      .provider-text-cv {
        color: #2b2b2b;
      }
      .provider-bank-option-cv {
        background-color: #fff;
        box-shadow: 0 2px 10pxrgba (0, 0, 0, 0.15);
        display: none;
        position: absolute;
        right: 0;
        top: 30px;
        width: 360px;
        z-index: 50;
        &.active-cv {
          display: block;
          border: 1px solid #0092ff;
          border-top: 0;
          width: 100%;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-top: 4px;
        }
        .provider-options-cv {
          background-color: #ffffff;
          margin-bottom: 5px;
          .option-cv {
            align-items: center;
            color: #333;
            display: flex;
            font: normal normal 400 13px/24px Montserrat;
            height: 40px;
            justify-content: flex-start;
            width: 100%;
            padding-left: 21px;
            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }
  .usertype {
    width: 50%;
    display: flex;
    .user-type-label {
      font-size: 14px;
      font-weight: 500;
      width: 32%;
      color: #43425d;
      -webkit-user-select: none;
      align-items: center;
      display: flex;
      gap: 4px;
      -ms-user-select: none;
      user-select: none;
      // margin-bottom: 2.1rem;
      margin-right: 8px;
      &::after {
        content: " *";
        color: red;
      }
    }
  }
  .user-divider {
    border-top: 1px solid #cbcbcb;
    margin: 2rem 0;
  }
  .user-name-main {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      position: static;
      margin-top: 20px;
    }
  }
  .input-user-form-fields {
    width: 100%;
    display: flex;
    height: 18%;
    align-items: center;
    .user-name-main {
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
      .name {
        width: 100%;
        display: flex;
        .user-input {
          width: 57%;
          border-radius: 10px;
          border: 1px solid #cbcbcb;
          position: relative;
          .user-input-field {
            background-color: transparent;
            outline: none;
            border: 0;
            font-size: 14px;
            padding: 8px 18px;
            width: 100%;
            font: normal normal 400 14px/24px Montserrat;
            user-select: none;
            color: #000000;

            &::placeholder {
              font-weight: normal;
              color: #9f9f9f;
            }

            &:focus {
              outline: none;
            }
            &:disabled {
              background-color: #f5f5f5;
              border: 1px solid #f5f5f5;
              border-radius: 10px;
            }
          }
        }
        .error-border {
          border: 1px solid red;
        }

        .label-tag {
          width: 32%;
          display: flex;
          margin-right: 10px;
          align-items: center;
          font-weight: 500;
          gap: 4px;
          &::after {
            content: " *";
            color: red;
          }
        }
        .label-tag-not-required {
          width: 32%;
          display: flex;
          margin-right: 10px;
          align-items: center;
          font-weight: 500;
          gap: 4px;
        }
        .user-name {
          width: 100%;
          height: 100%;
          .MuiFormControl-root {
            width: 70% !important;
          }
        }
      }
    }
    .email-name-main {
      width: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      .status {
        width: 50% !important;
      }
      .email {
        width: 100%;
        display: flex;
        align-items: center;
        .user-input {
          width: 57%;
          border-radius: 10px;
          border: 1px solid #cbcbcb;
          position: relative;
          .user-input-field {
            background-color: transparent;
            outline: none;
            border: 0;
            font-size: 14px;
            padding: 8px 18px;
            width: 100%;
            font: normal normal 400 14px/24px Montserrat;
            user-select: none;
            color: #000000;
            &:disabled {
              background-color: #f5f5f5;
              border: 1px solid #f5f5f5;
              border-radius: 10px;
            }

            &::placeholder {
              font-weight: normal;
              color: #9f9f9f;
            }

            &:focus {
              outline: none;
            }
          }
        }
        .error-border {
          border: 1px solid red;
        }
        .label-tag {
          width: 32%;
          display: inline;
          margin-right: 10px;
          align-items: center;
          font-weight: 500;
          &::after {
            content: " *";
            color: red;
          }
        }
        .user-email {
          width: 100%;
          height: 100%;
          .MuiFormControl-root {
            width: 70% !important;
          }
        }
      }
    }
  }

  .transfer-provider-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 0px;
    label {
      align-self: center;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #43425d;
      }
    }
  }

  .error-border {
    border: 1px solid red;
  }
  .hide {
    visibility: "hidden";
  }
  .disabled {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    pointer-events: none;
  }
  .provider-dropdown {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 57%;
    cursor: pointer;
    .text-dark {
      color: #000;
    }

    .text-light {
      color: #9f9f9f;
    }
    .dropdown-btn.border-radius__invert {
      border: 1px solid #0092ff;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // border-bottom: 0;
    }

    .provider-text {
      width: 100%;
      padding: 10px 18px;
      border-radius: 10px;
      background: transparent;
      border: 1px solid #cbcbcb;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      svg {
        color: #585757;
        height: 20px;
        margin-left: auto;
        // position: absolute;
        width: 20px;
        // right: 40px;
      }
      &.showl {
        border: 1px solid #0092ff;
        background-color: #fff;
        position: relative;
      }
      &.showl:hover {
        border: 1px solid #0092ff;
        background-color: #fff;
      }
    }
    .provider-text:hover {
      outline: none;
      border: 1px solid #000000;
    }

    .placeholder-text {
      font-weight: 400;
      color: #9f9f9f;
      font-size: 14px;
      padding-left: 2px;
    }
    .provider-text-span {
      color: #2b2b2b;
    }
    .provider-bank-option {
      display: none;
      position: absolute;
      background-color: #ffffff;
      top: 31px;
      right: 0;
      z-index: 50;
      width: 360px;
      &.active-beneficiary {
        border: 1px solid #0092ff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: 0;
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }
      .provider-options {
        background-color: #fff;
        margin-bottom: 5px;
        width: 100%;
        /* display: flex; */
        /* flex-direction: column; */
        justify-content: flex-start;
        align-items: start;
        .option {
          align-items: center;
          color: #464646;
          display: flex;
          font: normal normal 400 13px/24px Montserrat;
          height: 40px;
          justify-content: flex-start;
          padding-left: 21px;

          width: 100%;
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }

  .submit-btn-beneficiary {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    color: #d1d1d1;
    position: absolute;
    top: 540px;
    right: 130px;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    gap: 6px;
    &.active-beneficiary {
      background-color: #0094ff;
      color: white;
    }
  }
  #not-mandatory::after {
    content: "";
  }
}
