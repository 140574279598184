.change-log-contents {
    color: #000000;
    padding: 30px;

    h2 {
        margin-bottom: 40px;
    }

    h3 {
        font-size: 24px;
        color: #000000;
    }

    h4 {
        font-size: 20px;
        line-height: 24px;
    }


    ul {
        list-style: initial;

        li {
            margin-left: 50px;
            margin-top: 9px;
        }

        li:last-child {
            margin-bottom: 30px;
        }
    }

    h2,
    h3,
    h4,
    li {
        color: #000000;
    }
}