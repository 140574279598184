.virtual-accounts {
  div.close-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }
  .show{
    visibility: visible;

  }
  .required:after {
    content:" *";
    color: red;
  }

  .hide{
    visibility: hidden;
  }
  .in_not{
  position: absolute;
  right: 10px;
  height: 15px;
  }

  .MuiDialog-paper {
    overflow: visible !important;
  }

  &-heading {
    margin-top: 10px;
    margin-bottom: 5px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
    }
  }

  &-subtitle {
    text-align: left;
    font: normal normal normal 15px/18px Montserrat;
    font-weight: 600;
    letter-spacing: 0px;
    color: #808495;
    margin-bottom: 10px;
  }
  &-subtitle-all{
    text-align: left;
      font: normal normal normal 13px/16px Montserrat;
      font-weight: 600;
      letter-spacing: 0px;
      color: #808495;
      position: relative;
      &::after {
        color: red;
        content: " *";
        top: -4px;
        position: absolute;
        left: -6px;
    }
  }
  

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    .MuiOutlinedInput-input {
      padding: 10px 13px;
      font-family: "Montserrat";
    }
    .MuiOutlinedInput-input:-webkit-autofill {
      border-radius: 10px;
    }
    div.field-input {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      margin: 6px;
      label {
        align-self: center;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #43425d;
        }
      }
      fieldset {
        border-radius: 10px;
      }
      input::placeholder {
        font-size: 13.5px;
        color: #999393 !important;
      }
     
      .cva-provider-dropdown {
        width: 315px;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .cva-text-span {
          align-items: center;
          background: transparent;
          border: 1px solid #cbcbcb;
          border-radius: 10px;
          display: flex;
          font-size: 13px;
          height: 43px;
          font-weight: 500;
          justify-content: space-between;
          line-height: 20px;
          padding: 12px;
          width: 315px;
          svg {
            width: 20px;
            height: 20px;
            margin-left: auto;
            color: #727070;
          }
          &.show-border-class{
            border: 1px solid #0092ff;
          }
        }
        .cva-text-span:hover {
          outline: none;
          // border: 1px solid #0092ff;
        }
        .placeholder-text {
          color: #9f9f9f;
          font: normal normal 400 14px/24px Montserrat;
          opacity: 0.5;
        }
        .cva-provider-text {
          color: #2b2b2b;
        }
        .cva-provider-bank-option {
          display: none;
          position: absolute;
          background-color: #ffffff;
          top: 33px;
          right: 0;
          z-index: 50;
          width: 315px;
          &.cva-active {
            display: block;
            border: 1px solid #0092ff;
            border-top: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          .cva-provider-options {
            background-color: #ffffff;
            margin-bottom: 5px;
            .cva-option {
              font: normal normal 300 13px/24px Montserrat;
              color: #333;
              font-weight: 400;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 40px;
              user-select: none;
              padding-left: 13px;
              &:hover {
                background: #f4f4f4;
              }
            }
            .dropdown-arrow {
              width: 18px;
            }
            
            .dropdown-arrow__rotate {
              transition: transform 0.5s ease-in-out;
              transform: rotate(180deg);
            }
            
          }
        }
      }
    }

    .kyc-radio-input {
      border-radius: 5px;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 15px 20px;
      margin-top: 8px;
      div.kyc-toggle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #43425d;
        }

        .toggle-section {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #cbcbcb;
            font-weight: 500;
            margin: 5px;
          }

          .text-toggle {
            transition: all 0.5s;
            color: #0092ff;
          }

          .toggle {
            position: relative;
            display: inline-block;
            width: 34px;
            height: 18px;
            background-color: #e8e9ec;
            border-radius: 30px;
          }

          .toggle:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #0092ff;
            left: 0px;
            transition: all 0.5s;
          }

          .checkbox:checked + .toggle::after {
            left: 16px;
            right: 1px;
          }

          .checkbox:checked + .toggle {
            background-color: #e8e9ec;
          }

          .checkbox {
            display: none;
          }
        }
      }
      .kyc-toggle-divider {
        border: 1px solid #cbcbcb;
        height: 26px;
        margin: 0 16px;
      }
    }

    .submit-btn-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .submit-account-details {
        // border: none;
        outline: none;
        margin: 18px 0;
        margin-bottom: 15px;
        padding: 7px 30px;
        border-radius: 100px;
        // background: #0092ff;
        // color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        // opacity: 0.5;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        color: #d1d1d1;
        .rs-loader-wrapper {
          transform: translate(6px, 3px);
        }
      }
      .active-submit-btn {
        // opacity: 1;
        background: #0092ff;
        color: #ffffff;
        border: none;
      }
    }
  }

  .provider-dropdown {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .provider-show {
      width: 315px;
      padding: 8px 12px;
      border-radius: 10px;
      background: transparent;
      border: 1px solid #cbcbcb;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: auto;
        color: #848484;
      }
      &.show-border{
        border: 1px solid #0092ff;
      }
      &.show-border:hover{
        border: 1px solid #0092ff;

      }
    }
    .provider-show:hover {
      outline: none;
      border: 1px solid #000000;
    }
    .placeholder-text {
      color: #999393;
      font-weight: normal;
      opacity: 0.5;
    }
    .provider-text {
      color: #2b2b2b;
    }
    .provider-bank-option {
      background-color: #fff;
  box-shadow: 0 2px 10pxrgba(0,0,0,.15);
  display: none;
  position: absolute;
  right: 0;
  top: 30px;
  width: 315px;
  z-index: 50;
      &.active {
        display: block;
        border: 1px solid #0092ff;
        border-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .provider-options {
        background-color: #ffffff;
        margin-bottom: 5px;
        .option {
          align-items: center;
  color: #333;
  display: flex;
  font: normal normal 400 13px/24px Montserrat;
  height: 40px;
  justify-content: flex-start;
  width: 100%;
  padding-left: 12px;
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }

  .toggle-input {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    margin: 8px;
    span {
      align-self: center;
      font-weight: 600;
      color: #43425d;
    }
    .toggle-btn {
      width: 70%;
      input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      label {
        cursor: pointer;
        text-indent: -9999px;
        width: 46px;
        height: 26px;
        background: #d1d1d1;
        display: inline-block;
        border-radius: 100px;
        position: relative;
      }

      label:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 90px;
        transition: 0.5s;
      }

      input:checked + label {
        background: #0092ff;
      }

      input:checked + label:after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
      }

      label:active:after {
        width: 20px;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .upi-payment-details {
      outline: none;
      margin-top: 26px;
      margin-bottom: 18px;
      padding: 7px 30px;
      border-radius: 100px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
      color: #d1d1d1;
      .rs-loader-wrapper {
        transform: translate(6px, 3px);
      }
    }
    .active-upi-btn {
      background: #0092ff;
      color: #ffffff;
      border: none;
    }
  }
}
