$theme-color: #0092ff;
$color-gray: #a5a5a5;
$color-white-primary: #ffffff;
$color-background-secondary: #e5f4ff;
$color-background-disabled: #e8e9ec;

.ReportsForm {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  .labels {
    padding: 4px;
    margin-bottom: 4px;
    color: #43425d;
    font-weight: 500;
  }
  .email {
    color: #acabab;
  }
  .title {
    text-align: left;
    align-self: flex-start;
    color: #43425d;
    font-size: 17px;
    margin-left: 5px;
    margin-bottom: 32px;
    font-weight: 500;
  }

  .cas-message-container {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f2f9ff;
    border-radius: 10px;
    border: 1px solid #0092ff;
    padding: 0.8rem;
    width: auto;
    align-self: flex-start;
    img {
      margin-right: 8px;
    }
    .cas-message {
      color: #43425d;
      font-size: 0.765rem;
      .important-text-highlight {
        color: #000000;
        font-weight: 600;
      }

      .text-highlight {
        color: #0092ff;
        font-weight: 600;
      }
    }
  }

  .input-user-form-fields {
    width: 100%;
    display: flex;
    padding: 20px;
    height: 15%;
    align-items: center;
    div.kyc-toggle-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0.43;
      span {
        font-size: 14px;
        font-weight: 500;
        color: #43425d;
      }

      .toggle-section {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #cbcbcb;
          font-weight: 500;
          margin: 5px;
        }

        .text-toggle {
          transition: all 0.5s;
          color: #0092ff;
        }

        .toggle {
          position: relative;
          display: inline-block;
          width: 34px;
          height: 18px;
          background-color: #e8e9ec;
          border-radius: 30px;
        }

        .toggle:after {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #0092ff;
          left: 0px;
          transition: all 0.5s;
        }

        .checkbox:checked + .toggle::after {
          left: 16px;
          right: 1px;
        }

        .checkbox:checked + .toggle {
          background-color: #e8e9ec;
        }

        .checkbox {
          display: none;
        }
      }
    }
    .kyc-toggle-divider {
      // border: 1px solid #cbcbcb;
      flex: 0.063;
      margin: 0 6px;
    }
    .user-name-main {
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      flex: 1;

      .name {
        width: 100%;
        display: flex;
        .user-input {
          width: 57%;
          border-radius: 10px;
          position: relative;
          // border: 1px solid #cbcbcb;
          .date-selector-reports{
            left: 783.75px;
            right:unset !important;
            top: default;
          }
          .user-input-field {
            background-color: transparent;
            outline: none;
            border: 0;
            font-size: 14px;
            padding: 8px 18px;
            width: 100%;
            font: normal normal 400 14px/24px Montserrat;
            user-select: none;
            color: #000000;

            &::placeholder {
              font-weight: normal;
              color: #9f9f9f;
            }

            &:focus {
              outline: none;
            }
            &:disabled {
              background-color: #f5f5f5;
              border: 1px solid #f5f5f5;
              border-radius: 10px;
            }
          }
        }
        .error-border {
          border: 1px solid red;
        }

        .label-tag {
          width: 25%;
          display: flex;
          margin-right: 10px;
          align-items: center;
          font-weight: 500;
          gap: 4px;

          &::after {
            content: " *";
            color: red;
          }

          .info-icon {
            background: #ffff;
            img {
              background: #ffff;
              width: 0.8rem;
              height: 0.8rem;
              margin-bottom: 2px;
            }
          }
        }

        .label-tag-without-required {
          width: 25%;
          display: flex;
          margin-right: 10px;
          align-items: center;
          font-weight: 500;
          gap: 4px;
        }

        .user-name {
          width: 100%;
          height: 100%;
          .MuiFormControl-root {
            width: 70% !important;
          }
        }
      }
    }
    .email-name-main {
      width: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      .status {
        width: 50% !important;
      }
      .email {
        width: 100%;
        display: flex;
        align-items: center;
        .user-input {
          width: 57%;
          border-radius: 10px;
          border: 1px solid #cbcbcb;
          position: relative;
          .user-input-field {
            background-color: transparent;
            outline: none;
            border: 0;
            font-size: 14px;
            padding: 8px 18px;
            width: 100%;
            font: normal normal 400 14px/24px Montserrat;
            user-select: none;
            color: #000000;
            &:disabled {
              background-color: #f5f5f5;
              border: 1px solid #f5f5f5;
              border-radius: 10px;
            }

            &::placeholder {
              font-weight: normal;
              color: #9f9f9f;
            }

            &:focus {
              outline: none;
            }
          }
        }
        .error-border {
          border: 1px solid red;
        }
        .label-tag {
          width: 25%;
          display: inline;
          margin-right: 10px;
          align-items: center;
          font-weight: 500;
          &::after {
            content: " *";
            color: red;
          }
        }
        .user-email {
          width: 100%;
          height: 100%;
          .MuiFormControl-root {
            width: 70% !important;
          }
        }
      }
    }
  }

  .generate-btn__container {
    width: 297px;
  }
  .inactiveBtn {
    background-color: #cbcbcb;
    pointer-events: none;
  }
  .activeBtn {
    background: $theme-color;
  }
  .generateButton {
    width: 192px;
    margin-left: 1.5rem;
    height: 40px;
    color: #ffffff;
    margin-top: 30px;
    display: flex;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 20px;
    gap: 6px;
    align-items: center;
    justify-content: center;
    .report-loader {
      align-self: center;
      margin: 4px;
      padding: 4px;
    }
  }
}

@media screen and (max-width: 1411px) {
  .ReportsForm {
    .ReportsBottomrow {
      .generateButton {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 1069px) {
  .ReportsForm {
    .ReportsToprow {
      justify-content: center;
      gap: 1rem;
    }
    .ReportsBottomrow {
      justify-content: center;
      gap: 1rem;
    }
  }
}

.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: #ffffff00;
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.5em;
  background: #fff;
  border: 1px solid #cbcbcb;
  color: #000000de;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  border-color: #0092ff;
  background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #403d3d;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.4em 0.8em;
  color: #00000099;
  text-transform: none;
  overflow-wrap: anywhere;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
