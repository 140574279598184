.forgot-password {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  .password-section {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-section {
      width: 65%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
      .fixed-logo-new {
        position: absolute;
        top: 0;
        width: 150px;
      }
      .reset-message {
        text-align: center;
      }
      h1 {
        font-size: 17px;
        color: #4d4f5c;
        font-weight: 500;
        margin-top: 20px;
      }
      .input-section {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 15px 0 0 0;
        width: 100%;
        button.back-login {
          display: flex;
          margin: 15px auto 0px auto;
          border-radius: 25px;
          background: #0092ff;
          padding: 10px 40px;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          border: 1px solid transparent !important;
        }
        .password-form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
        }
        form {
          width: 100%;
          .inputs {
            display: flex;
            flex-flow: column;
            width: 100%;
            gap: 5px;
            .input-error {
              border-color: #de6767;
              // color: #de6767;
            }
            .error {
              color: #de6767;
              font-size: 12px;
            }
            input {
              width: 100%;
              font-weight: 600;
              //   margin-top: 45px;
            }
            label {
              font-weight: 600;
            }
          }
          button.login-button {
            display: flex;
            margin: 15px auto 0px auto;
            border-radius: 25px;
            background: #0092ff;
            padding: 10px 40px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            border: 1px solid transparent !important;
          }
          button:disabled {
            background: #4d4f5c;
            color: #d1d1d1;
            border: 1px solid #f5f5f5;
            background-color: #f5f5f5;
          }
          .back-to-login {
            color: #0092ff;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .forgot-password {
    .password-section {
      width: 100%;
    }
  }
}
