.provider-dropdowns {
  position: relative;
  .provider-bank-option {
    position: absolute;
    z-index: 1;
    top: 100%;
    width: 100%;
    background-color: white;
    padding: 0.2rem 0;
    .provider-options {
      border-radius: 3px;
      max-height: 160px;
      overflow-y: auto;
      > .options {
        :hover {
          background-color: #0092ff;
          color: #fff;
        }
        .option {
          padding: 0.5rem 1rem;
          transition: 0.3s;
        }
      }
    }
  }
}
.provider-dropdowns {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .provider-show {
    width: 100%;
    padding: 10px 12px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #cbcbcb;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    svg,
    img {
      width: 20px;
      height: 20px;
      margin-left: auto;
      color: #848484;
    }
    &.show-border {
      border: 1px solid #0092ff;
    }
    &.show-border:hover {
      border: 1px solid #0092ff;
    }
  }
  .provider-show:hover {
    outline: none;
    // border: 1px solid #000000;
  }
  .placeholder-text {
    color: #403d3d;
    font-weight: 400;
    opacity: 0.5;
  }

  .provider-text-er {
    color: black;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .provider-bank-option {
    background-color: #fff;
    box-shadow: 0 2px 10pxrgba (0, 0, 0, 0.15);
    display: none;
    position: absolute;
    right: 0;
    top: 30px;
    width: 100%;
    z-index: 50;
    &.active-dropdown {
      display: block;
      border: 1px solid #0092ff;
      border-top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .provider-options {
      background-color: #ffffff;
      margin-bottom: 5px;
      .option {
        align-items: center;
        color: #333;
        display: flex;
        font: normal normal 400 13px/24px Montserrat;
        height: 40px;
        justify-content: flex-start;
        width: 100%;
        padding-left: 12px;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.disable-dropdown {
  background-color: #eaedf0;
  border-radius: 10px;
  pointer-events: none;
}
