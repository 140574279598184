.banner{
	width: 50%;
	height: 100%;
	position: relative;
	overflow: hidden;
	img{
		width: 100%;
        height: 100%;
	}
	.bannerText{
		position: absolute;
		bottom: 100px;
		left: 50px;
		h3{
			font-size: 30px;
		}
		h2{
			font-size: 40px;
		}
		h3,h2{
			color: #ffffff;
			font-weight: 500;
		}
	}
}


@media screen and (max-width: 1280px){
    .banner{
        display: none;
    }
}
