.report-success {
  div.close-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }

  &-heading {
    margin-bottom: 10px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
      margin-top: 0.5rem;
    }
  }

  .submit-success-content {
    background: #f1fcf6;
    border-radius: 5px;
    width: 500px;
    height: 250px;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.2rem;
    animation: 0.4s ease-in both fade-in;

    .verified-icon {
      font-size: 4rem !important;
      color: #4ad991 !important;
    }
    h3 {
      color: #4d4f5c;
      font-size: 1rem;
      font: normal normal normal Montserrat;
    }
    p {
      color: #808495;
      margin-top: -5px;
      font: normal normal normal 13px/16px Montserrat;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  @keyframes fade-in {
    0% {
      transform-origin: center;
      transform: translate(0, -30px);
      opacity: 0;
    }

    100% {
      transform-origin: center;
      transform: translate(0, 0px);
      opacity: 1;
    }
  }
}
