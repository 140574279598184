.menu-tabs{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .tab{
        background-color: #FFFFFF;
        font-size: 14px;
       
        line-height: 18px;
        text-align: center;
        font-weight: 600;
        padding: 10px 26px;
        padding-top: 0;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        &:first-child{
            padding-left: 17px;
        }
        a{
            color: #43425D;
            padding: 20px 10px;
            &.active{
                color: #0092FF;
            }
        }
        &.active{
            border-bottom: 2px solid #0092FF;
        }
    }
}



