.virtual-accounts-api-graphs {
  .row-one {
    display: flex;
    flex-flow: row;
    flex: 1;
    .graph {
      flex: 1;
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
          font-size: 16px;
          font-weight: 500;
          color: #43425d;
          line-height: 20px;
        }
        img {
          width: 18px;
          cursor: pointer;
        }
      }
      .chart {
        position: relative;
        width: 100%;
      }
    }
  }
  .row-two {
    margin-top: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 20px;
    .text-block {
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 28px;
      .text-container {
        h3 {
          font-size: 16px;
          font-weight: 500;
          color: #43425d;
          line-height: 20px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #4d4f5c;
          margin-top: 15px;
        }
        .text-points {
          margin-top: 15px;
          p {
            strong {
              font-weight: 600;
            }
          }
        }
      }
      a {
        width: max-content;
        // margin-top: 30px;
        padding: 10px 25px;
        border-radius: 100px;
        background: #0092ff;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .credits-utilised-graph.graph,
    .api-usage-graph.graph {
      display: flex;
      flex-direction: column;
      gap: 35px;
      flex: 1;
      height: 100%;
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
          font-size: 16px;
          font-weight: 500;
          color: #43425d;
          line-height: 20px;
        }
        img {
          width: 18px;
          cursor: pointer;
        }
      }
      .chart {
        position: relative;
        align-self: center;
      }
    }
  }
  // Text Block Responsiveness
  @media screen and (max-width: 1350px) {
    .text-block {
      grid-column: 1/-1;
    }
  }
}
.virtual-accounts {
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
  }
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 146, 255, 1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 5px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: rgba(0, 146, 255, 1);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}

.credits-summary-row {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #0000000a;
  .for-all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,
    .right {
      display: flex;
      align-items: center;
      .summary-content {
        display: flex;
        align-items: center;
        label {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 23, 55, 0.5);
        }
        h3 {
          font-size: 18px;
          color: #001737;
          font-weight: 500;
          margin: 0px 20px 0 15px;
          width: max-content;
        }
        #right-summary_value {
          margin: 0px;
        }
        svg {
          margin: 0 8px;
          font-size: 16px;
          color: #43425d;
        }
        &:first-child {
          margin-left: 0;
        }
        .error-icon {
          transform: rotate(180deg);
        }
        margin-left: 15px;
      }
      .divider {
        border-right: 1px solid #9f9f9f;
      }
      button {
        background: #0092ff;
        border-radius: 10px;
        font-size: 11px;
        color: #ffffff;
        font-weight: 600;
        padding: 3px 12px;
      }
    }
  }
}
