.ButtonWrapper {
  background-color: #fff;
  border-radius: 10px;
  min-height: 100%;
  .total-count {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 1.5rem;
    flex-wrap: wrap;
    .entriesFoundContainer {
      padding-left: 0.5rem;
    }
    .title {
      font-size: 17px !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      img {
        cursor: pointer;
      }
      span:not(.text-primary) {
        color: #a2a2a2 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
      }
      .text-primary-number {
        color: #0092ff;
      }
    }
    .add-callback-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      border: none;
      outline: none;
      padding: 0.725rem 28px;
      border-radius: 100px;
      background: #0092ff;
      color: #ffffff;
      opacity: 1;
      font-size: 14px;
      font-weight: 500;
      span {
        font-size: 1.4rem;
      }
    }
    .virtual-accounts-btn {
      align-items: center;
      background: #0092ff;
      border: none;
      border-radius: 20px;
      color: #fff;
      // background-color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      // background-color: #fff;
      gap: 6px;
      justify-content: center;
      margin: 0;
      outline: none;
      padding: 0.6rem 1.5rem;
      .refreshing-btn {
        button {
          background-color: #daf7e8;
          color: #4ad991;
          cursor: not-allowed;
        }
      }
    }
    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.filter-data-wrapper {
  display: flex;
  // align-items: center;
  align-items: flex-end;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
  // padding: 30px 1.5rem 8px 1.5rem;
  padding: 1.6rem 1.5rem 8px;
  position: sticky;
  top: -2rem;
  z-index: 2;
  background: #fff;

  .search-filter-wrapper {
    width: 24%;
    background-color: #fff !important;
    border: 1px solid #cecfd0;
    font-weight: 200;
    // padding: 0.4rem 1.5rem;
    padding: 0.5rem 1.25rem;
    border-radius: 100px;
    display: flex;
    position: relative;
    flex-flow: column;
    .searchbar-input,
    input {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      color: #000;
      font-weight: 300;
    }
  }
  .search-container {
    // background-color: #fff !important;
    // border: 1px solid #cecfd0 !important;
    // border-radius: 100px;
    display: flex;
    flex-flow: column;
    font-weight: 200;
    gap: 5px;
    // padding: 0.4rem 1.5rem;
    // position: relative;
    // width: 24%;
    .search-scope {
      // font-size: 12px;
      font-size: 8px;
      font-weight: 500;
      color: #000000;
      span {
        color: #0092ff;
      }
    }
    .search-filter-wrapper {
      // width: 24%;
      width: 100%;
      background-color: #fff !important;
      border: 1px solid #cecfd0;
      font-weight: 200;
      // padding: 0.4rem 1.5rem;
      padding: 0.5rem 1.25rem;
      border-radius: 100px;
      display: flex;
      position: relative;
      flex-flow: column;
      &.show-border-cv {
        border: 1px solid #0092ff;
        // border-radius: 8px;
        // border-radius: 20px;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .advanced-search-container {
        display: flex;
        width: 100%;
      }
      .cursor-pointer {
        cursor: pointer;
      }
      .advanced-search-option {
        position: absolute;
        z-index: 1;
        background: #ffffff;
        width: 100%;
        left: -1px;
        top: 26px;
        background-color: #fff;
        box-shadow: 0 2px 10pxrgba (0, 0, 0, 0.15);
        display: none;
        right: 0;
        width: calc(100% + 2px);
        &.active-cv {
          display: block;
          border: 1px solid #0092ff;
          border-top: 0;
          // border-bottom-left-radius: 8px;
          // border-bottom-right-radius: 8px;
          border-bottom-left-radius: 22px;
          border-bottom-right-radius: 22px;
          margin-top: 4px;
          z-index: 100;
          overflow: hidden;
        }
        .options {
          // background-color: #ffffff;
          background-color: transparent;
          margin-bottom: 5px;
          cursor: pointer;
          .option-cv {
            align-items: center;
            color: #333;
            display: flex;
            font: normal normal 400 13px/24px Montserrat;
            height: 40px;
            justify-content: flex-start;
            width: 100%;
            // padding-left: 21px;
            padding: 0.6rem 1.25rem;
            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
      img {
        cursor: pointer;
      }
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: #000;
        font-weight: 300;
        &::-webkit-search-cancel-button {
          background-color: #000;
          color: #000;
        }
      }
    }
  }

  .provider-show-cv {
    width: 100%;
    padding: 10px 18px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #cbcbcb;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    // justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      margin-left: auto;
      color: #848484;
    }
    &.show-border-cv {
      border: 1px solid #0092ff;
    }
    &.show-border-cv:hover {
      border: 1px solid #0092ff;
    }
  }
  .provider-show-cv:hover {
    outline: none;
    border: 1px solid #000000;
  }
  .download-csv-main {
    // width: 10%;
    display: flex;
    justify-content: center;
    button {
      display: flex;
      gap: 6px;
      background-color: #e3f1fb !important;
      border: 1px solid #e3f1fb !important;
      font-weight: 600;
      padding: 0.6rem 1.25rem;
      border-radius: 100px;
      color: #0092ff;
      font-size: 12px;
      line-height: 20px;
      width: max-content;
      white-space: nowrap;
      align-items: center;
    }
  }

  .download-csv-main-disable {
    // width: 10%;
    display: flex;
    justify-content: center;
    button {
      display: flex;
      gap: 6px;
      background-color: #f6fbfd !important;
      border: 1px solid #f6fbfd !important;
      font-weight: 600;
      padding: 0.6rem 1.25rem;
      border-radius: 100px;
      color: #bfdeff;
      font-size: 12px;
      line-height: 20px;
      width: max-content;
      white-space: nowrap;
      align-items: center;
    }
  }

  .download-csv-main-loading {
    // width: 10%;
    display: flex;
    justify-content: center;
    button {
      display: flex;
      gap: 6px;
      font-weight: 600;
      padding: 0.6rem 1.25rem;
      border-radius: 100px;
      background-color: #f6fbfd !important;
      border: 1px solid #f6fbfd !important;
      border-radius: 100px;
      color: #c4e1ff;
      font-size: 12px;
      line-height: 20px;
      width: max-content;
      white-space: nowrap;
      align-items: center;
      cursor: not-allowed;
    }
  }

  .refreshing-btn {
    color: #4ad991;

    button {
      background-color: #daf7e8;
      cursor: not-allowed;
      color: #4ad991;
    }
    img {
      width: 10px;
    }
  }
}
.modal {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  overflow: auto;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  z-index: 2000;
}

/* Modal Content */
.modal-content {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-name: animatetop;
  animation-name: animatetop;
  background-color: #fefefe;
  /* border: 1px solid #888; */
  border-radius: 10px;
  box-shadow: 0 3px 20px #0000001a;
  height: 294px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 554px;
  display: flex;
  padding: 10px;
  position: relative;
}
.button_container_csv {
  background: #0092ff;
  border-radius: 18px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 28px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  &:hover {
    background-color: #0092ff;
    color: #fff;
  }
}
.button-holder {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  margin-top: 5px;
}
.cancel {
  width: 90px;
  border-radius: 30px;
  padding: 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #43425d;
}
.modal-header {
  // background-color: #5cb85c;
  color: #43425d;
  font-size: 22px;
  font-weight: 600;
  height: 40px;
  padding: 23px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  position: relative;
}
.main_msg {
  color: #808495;
  font: normal normal normal 15px/18px Montserrat;
  font-weight: 600;
  letter-spacing: 0;
  padding: 10px;
}

.modal-body {
  align-items: center;
  background: #e5f4ff;
  display: flex;
  height: 140px;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 93%;
  .text-area {
    min-width: 500px;
    border-radius: 5px;
    min-height: 125px;
    border: 2px solid #eaeaea;
    padding: 10px;
    resize: none;
  }
  .message {
    color: #808495;
    font: normal normal normal 13px/16px Montserrat;
    font-weight: 600;
    letter-spacing: 0;
  }
}

.show-modules-wrapper-main {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  .modules-wrapper-main {
    button {
      background-color: #e3f1fb !important;
      border: 1px solid #e3f1fb !important;
      border-radius: 100px;
      color: #0092ff;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      padding: 0.6rem 1.25rem;
      // width: max-content;
      white-space: nowrap;
      width: 185px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .block-main {
      display: flex;
      position: absolute;
      min-width: 185px;
      width: auto;
      z-index: 1;
      top: 57px;
      align-items: center;
      justify-content: center;
      right: 0;
      background-color: white !important;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1019607843);
    }
    .hidden {
      display: none;
    }
    ul {
      width: 100%;
      padding: 8px;
      li {
        padding: 10px 10px 10px 15px;
        font-size: 13px;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        display: flex;
        border-bottom: 1px solid #e4e4e4;
        &:last-child {
          border-bottom: none;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: #0092ff;
          color: white;
        }
      }
    }
  }
}

.show-modules-wrapper-main-loading {
  position: relative;
  background: #fff;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  .modules-wrapper-main {
    button {
      background-color: #f6fbfd !important;
      border: 1px solid #f6fbfd !important;
      border-radius: 100px;
      color: #c4e1ff;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      padding: 0.6rem 1.25rem;
      width: max-content;
      white-space: nowrap;
      cursor: not-allowed;
      width: 185px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .block-main {
      display: flex;
      position: absolute;
      min-width: 185px;
      width: auto;
      z-index: 1;
      top: 57px;
      align-items: center;
      justify-content: center;
      right: 0;
      background-color: white !important;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1019607843);
    }
    .hidden {
      display: none;
    }
    ul {
      width: 100%;
      padding: 8px;
      li {
        padding: 10px 10px 10px 15px;
        font-size: 13px;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        display: flex;
        border-bottom: 1px solid #e4e4e4;
        &:last-child {
          border-bottom: none;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: #0092ff;
          color: white;
        }
      }
    }
  }
}

.show-entries-wrapper-main {
  position: relative;
  background: #fff;
  // z-index: 10;
  // width: 12%;
  display: flex;
  justify-content: center;
  .entries-wrapper-main {
    button {
      background-color: #e3f1fb !important;
      border: 1px solid #e3f1fb !important;
      border-radius: 100px;
      color: #0092ff;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      padding: 0.6rem 1.25rem;
      width: max-content;
      white-space: nowrap;
    }

    .block-main {
      display: flex;
      position: absolute;
      width: 65px;
      z-index: 1;
      top: 57px;
      align-items: center;
      justify-content: center;
      right: 36px;
      background-color: white !important;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1019607843);
    }
    .hidden {
      display: none;
    }
    ul {
      width: 100%;
      padding: 8px;
      li {
        padding: 10px 10px 10px 15px;
        font-size: 13px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: flex;
        border-bottom: 1px solid #e4e4e4;
        &:last-child {
          border-bottom: none;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: #0092ff;
          color: white;
        }
      }
    }
  }
}

.show-entries-wrapper-main-loading {
  position: relative;
  background: #fff;
  cursor: not-allowed;

  // z-index: 10;
  // width: 12%;
  display: flex;
  justify-content: center;
  .entries-wrapper-main {
    button {
      background-color: #f6fbfd !important;
      border: 1px solid #f6fbfd !important;
      border-radius: 100px;
      color: #c4e1ff;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      padding: 0.6rem 1.25rem;
      width: max-content;
      white-space: nowrap;
      cursor: not-allowed;
    }
  }
}

.show-column-wrapper-main {
  position: relative;
  // z-index: 10;
  // width: 13%;
  display: flex;
  justify-content: center;
  .column-wrapper {
    button {
      background-color: #e3f1fb !important;
      // border: 1px solid #e3f1fb !important;
      font-weight: 600;
      // padding: 0.59rem 1.25rem;
      padding: 0.6rem 1.25rem;
      border-radius: 100px;
      color: #0092ff;
      font-size: 12px;
      line-height: 20px;
      width: max-content;
      white-space: nowrap;
    }
    .checkboxIcon {
      height: 0.75rem;
      width: 0.75rem;
      border: 1.5px solid #0092ff;
      border-radius: 2px;
      position: relative;
      .checkIcon {
        position: absolute;
        left: 0.1rem;
        top: -0.4rem;
        font-size: 0.8rem;
        color: #0092ff;
      }
    }
    .block {
      display: flex;
      position: absolute;
      width: 203px;
      top: 57px;
      flex-flow: row-reverse;
      padding: 5px;
      z-index: 1;
      background-color: white !important;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1019607843);
    }
    .hidden {
      display: none;
    }
    ul {
      width: 100%;
      padding: 5px;
      li {
        > label {
          display: flex;
          width: 100%;
          font-size: 12px;
          // gap: 14px;
          text-transform: capitalize;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
        padding: 9px 11px 9px 10px;
        display: flex;
        gap: 5px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(226, 226, 226);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.show-column-wrapper-main-loading {
  position: relative;
  // z-index: 10;
  // width: 13%;
  display: flex;
  justify-content: center;
  .column-wrapper {
    button {
      background-color: #f6fbfd !important;
      // border: 1px solid #e3f1fb !important;
      font-weight: 600;
      // padding: 0.59rem 1.25rem;
      padding: 0.6rem 1.25rem;
      border-radius: 100px;
      color: #c4e1ff;
      font-size: 12px;
      line-height: 20px;
      width: max-content;
      white-space: nowrap;
      cursor: not-allowed;
    }
  }
}

.csvModal {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  .content-modal {
    text-align: center;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 40%;
    min-width: 400px;
    padding: 1.5rem;
    p {
      margin: 2rem 0;
    }
    .modal-btns {
      display: flex;
      gap: 10px;
      justify-content: center;
    }
  }
}

.module-dropdown-wrapper {
  background-color: #e3f1fb !important;
  border: 1px solid #e3f1fb !important;
  border-radius: 100px;
  color: #0092ff;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0.6rem 1.25rem;
  width: max-content;
  white-space: nowrap;
  .module-dropdown {
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: #e3f1fb !important;
    option {
      background-color: white !important;
      border-radius: 10px !important;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1019607843) !important;
    }
  }
}

.module-dropdown-wrapper-loading {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e3f1fb !important;
  background-color: #f6fbfd !important;
  border-radius: 100px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0.6rem 1.25rem;
  width: max-content;
  white-space: nowrap;
  color: #c4e1ff;
  min-width: 170px;
  .module-dropdown {
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: #f6fbfd !important;
    option {
      background-color: #f6fbfd !important;
      border-radius: 10px !important;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1019607843) !important;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .show-column-wrapper {
    border: 1px solid brown;
  }
}

@media screen and (max-width: 1280px) {
  .show-column-wrapper {
    border: 1px solid green;
  }
  #datecont {
    width: 157px;
  }
  #datecont > .rs-picker-default .rs-picker-toggle.rs-btn {
    background-color: #e3f1fb !important;
    border: 1px solid #e3f1fb !important;
    font-weight: 600;
    padding: 6px 15px;
    font-size: 10px;
    padding-right: 36px;
  }
}

//containers done

@media screen and (max-width: 767px) {
  .show-column-wrapper {
    border: 1px solid yellow;
  }
}

@media screen and (max-width: 640px) {
  .show-column-wrapper {
    border: 1px solid red;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .show-column-wrapper {
    border: 1px solid #0092ff;
  }

  .LinkTableWrapper #datecont > .rs-picker-default .rs-picker-toggle.rs-btn {
    background-color: #e3f1fb !important;
    border: 1px solid #e3f1fb !important;
    font-weight: 600;
    padding: 6px 15px;
    font-size: 10px;
    padding-right: 36px;
  }
}

.ml-auto {
  margin-left: auto;
}

.timer {
  padding-right: 0.4rem;
}
.tableContainer {
  // max-height: 50vh;
  // overflow-y:auto;
  thead {
    position: sticky;
    top: 0rem;
    z-index: 1;
  }
}
.header-small {
  width: 10vw;
}
.last-child-header {
  text-align: left;
  margin-right: 8px;
}
.callback-table-edit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-weight: 600;
  color: #0092ff;
  background: none;
  padding: 8px;
  img {
    transform: rotate(180deg);
    width: 1rem;
  }
}
