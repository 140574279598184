.stickyFooter{
	position: fixed;
	bottom: 0;
	background: #EDF0F5;
	height: 70px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	a{
		font-size: 12px;
		color: #4D4F5C;
		margin: 0 22px;
		background: transparent;
		&:first-child{
			margin-right: 22px;
			margin-left: 0px;
		}
		&:last-child{
			margin-right: 0px;
			margin-left: 22px;
		}
	}
}

@media screen and (max-width: 710px) {
    .stickyFooter{
        flex-flow: column;
        height: 100px;
        a{
            margin: 0 auto!important;
            margin-bottom: 5px!important;
        }
    }
}