
button.upgrade-button{
    position: absolute;
    top: -70px;
    right: 0;
    padding: 10px 25px;
    border-radius: 100px;
    background: #0092FF;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.credits-utilised-graph-block{
    // border: 1px solid red;
    display: flex;
    flex-flow: column;
    margin-top: 25px;
    .chart-section{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-evenly;
        .chart-labels{
            display: flex;
            flex-flow: column;
            padding: 10px 0 0 0;
            width: 140px;
            margin-left: 30px;
            .legend{
                display: flex;
                flex-flow: row;
                align-items: flex-start;
                padding-bottom: 15px;
                border-bottom: 1px solid rgba(35, 36, 37, 0.2);
                margin-top: 20px;
                margin-left: 0px;
                white-space: nowrap;
                &:nth-child(1){
                    margin-top: unset;
                }
                &:last-child{
                    border: unset;
                    padding: 0;
                }
                .color-dot{
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                }
                .text{
                    display: flex;
                    flex-flow: column;
                    align-items: flex-start;
                    margin-top: -5px;
                    margin-left: 7px;
                    width: calc(100% - 15px);
                    h2{
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #232425;
                    }
                    label{
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        color: rgba(35, 36, 37, 0.8);
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    button{
        height: 48px;
        margin-left: auto;
    }
    .recharts-surface{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: rgba(35, 36, 37, 1);
        text:nth-child(2){
            font-size: 1rem;
        }
        text:nth-child(3),
        text:nth-child(4){
            font-size: 1rem;
            padding-top:0.2rem;
            font-weight: 500;
            opacity: 0.8;
        }
    }
}