.money-transfer {
  &-heading {
    margin-top: 10px;
    margin-bottom: 5px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
    }
  }
  .show {
    visibility: visible;
  }
  .hide {
    visibility: hidden;
  }
  .in_not {
    position: absolute;
    right: 10px;
    height: 15px;
  }
  div.close-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }

  .MuiDialog-paper {
    overflow: visible !important;
  }

  &-subtitle {
    text-align: left;
    font: normal normal normal 15px/18px Montserrat;
    margin-bottom: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #808495;
    
  }
  .money-transfer-subtitle-all {
    text-align: left;
    font: normal normal normal 13px/16px Montserrat;
    font-weight: 600;
    letter-spacing: 0px;
    color: #808495;
    position: relative;
    margin-top: 10px;
    &::after {
      color: red;
      content: " *";
      top: -4px;
      position: absolute;
      left: -6px;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    .MuiOutlinedInput-input {
      padding: 10px 13px;
      font-family: "Montserrat";
    }

    div.field-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      gap: 32px;
      margin: 7px;
      label {
        align-self: center;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #43425d;
        }
      }
      fieldset {
        border-radius: 10px;
      }
      input::placeholder {
        font-size: 13.5px;
        color: #999393 !important;
      }
    }

    .transfer-provider-code {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 7px 0px;
      label {
        align-self: center;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #43425d;
        }
      }
    }

    .provider-dropdown {
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .text-dark {
        color: #000;
      }

      .text-light {
        color: #9f9f9f;
      }
      .dropdown-btn.border-radius__invert {
        border: 1px solid #0092ff;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // border-bottom: 0;
      }

      .provider-text {
        width: 315px;
        padding: 8px 12px;
        border-radius: 10px;
        background: transparent;
        border: 1px solid #cbcbcb;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        svg {
          color: #585757;
          height: 20px;
          margin-left: auto;
          // position: absolute;
          width: 20px;
          // right: 40px;
        }
        &.showl {
          border: 1px solid #0092ff;
          background-color: #fff;
          position: relative;
        }
        &.showl:hover {
          border: 1px solid #0092ff;
          background-color: #fff;
        }
      }
      .provider-text:hover {
        outline: none;
        border: 1px solid #000000;
      }

      .placeholder-text {
        color: #999393;
        font-weight: normal;
        opacity: 0.5;
      }
      .provider-text-span {
        color: #2b2b2b;
      }
      .provider-bank-option {
        display: none;
        position: absolute;
        background-color: #ffffff;
        top: 31px;
        right: 0;
        z-index: 50;
        width: 315px;
        &.active {
          border: 1px solid #0092ff;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top: 0;
          display: flex;
          justify-content: flex-start;
        }
        .provider-options {
          background-color: #fff;
          margin-bottom: 5px;
          width: 100%;
          /* display: flex; */
          /* flex-direction: column; */
          justify-content: flex-start;
          align-items: start;
          .option {
            align-items: center;
            color: #464646;
            display: flex;
            font: normal normal 400 13px/24px Montserrat;
            height: 40px;
            justify-content: flex-start;
            padding-left: 14px;

            width: 100%;
            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }

    .next-btn-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .submit-transfer-details {
        // border: none;
        outline: none;
        margin-top: 26px;
        margin-bottom: 18px;
        padding: 7px 30px;
        border-radius: 100px;

        // opacity: 0.5;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        color: #d1d1d1;
      }
      .active-next-btn {
        // opacity: 1;
        background: #0092ff;
        color: #ffffff;
        border: none;
      }
    }
  }
}
