.pending{
    color:  #EDD058;
    font-weight: 800;
}
.failure{
    color:#D87B7B;
    font-weight: 800;
}
.success{
    color: #71BA7B;
    font-weight: 800;
}