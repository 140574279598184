.send-cred-success {
    div.close-popup {
      right: -28px;
      top: -28px;
      position: absolute;
      z-index: 100;
      background: #f2f9ff 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: #0092ff;
      }
    }
  
    &-heading {
      margin: 10px 0;
      p {
        font-size: 22px;
        font-weight: 600;
        color: #43425d;
      }
    }
  
    .submit-success-content {
      background: #f1fcf6;
      border-radius: 5px;
      width: 500px;
      height: 250px;
      margin-bottom: 1rem;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      animation: 0.4s ease-in both fade-in;
  
      .verified-icon {
        font-size: 4rem !important;
        color: #4ad991 !important;
      }
      h3 {
        color: #4d4f5c;
        font-size: 1rem;
      }
      p {
        color: #808495;
        margin-top: -5px;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  
    @keyframes fade-in {
      0% {
        transform-origin: center;
        transform: translate(0, -30px);
        opacity: 0;
      }
    
      100% {
        transform-origin: center;
        transform: translate(0, 0px);
        opacity: 1;
      }
    }
  }
  