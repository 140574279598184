@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #0092ff;
}

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
ul,
li {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

input {
  outline: none;
  border: none;
}

select,
textarea {
  outline: none;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
}

div {
  outline: none;
}

div#root {
  display: flex;
  height: 100%;
  width: 100%;
}

input.type1 {
  font-size: 14px;
  color: #43425d;
  font-weight: 300;
  // padding: 0 3px 10px 0;
  padding: 0.6rem 0.8rem;
  border-radius: 10px;
  border: 1px solid rgba(67, 66, 93, 0.3);
}

.title-case {
  text-transform: capitalize;
}

.paginate_button {
  height: 45px !important;
  width: 45px !important;
}

.paginate_button:hover {
  height: 45px !important;
  width: 45px !important;
  border-radius: 100% !important;
  display: inline-flex !important;
  flex-flow: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.sorting_disabled {
  padding-bottom: 28px !important;
}

#count_holder {
  color: #a2a2a2 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 21px !important;
}

.count_loader_img {
  width: 18px !important;
  height: 18px !important;
}

.showpageinfo .title,
.topwrapper .title {
  display: flex !important;
  font-size: 17px !important;
  font-weight: 600;
  gap: 12px !important;
  align-items: flex-end !important;
  justify-content: center !important;
}

.paginate_button.current {
  height: 45px !important;
  width: 45px !important;
  border-radius: 100% !important;
  display: inline-flex !important;
  flex-flow: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.paginate_button.previous,
.paginate_button.next {
  width: max-content !important;
  height: max-content !important;
  position: relative !important;
  margin-top: -5px !important;
}

.paginate_button.next:hover {
  border-radius: 10px !important;
  width: max-content !important;
  height: max-content !important;
}

.paginate_button.previous:hover {
  border-radius: 10px !important;
  width: max-content !important;
  height: max-content !important;
}

th {
  img {
    // margin-left: 8px !important;
  }
}

.btn-loading {
  align-items: center;
  background: #f6fbfd !important;
  border: none;
  border-radius: 20px;
  color: #bfdeff;
  cursor: not-allowed;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  gap: 6px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 8px 20px;
}

.add-callback-btn-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border: none;
  outline: none;
  padding: 0.725rem 28px;
  border-radius: 100px;
  background: #f6fbfd;
  color: #bfdeff;
  opacity: 1;
  cursor: not-allowed;
  font-size: 14px;
  font-weight: 500;
  span {
    font-size: 1.4rem;
  }
}

.max-z-index {
  z-index: 999;
}
