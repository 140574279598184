div.usage-graph-menu{
    position: absolute;
    top: -58px;
    right: 0;
    cursor: pointer;
    .usage-graph-dropdown{
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .usage-graph-dropdown-content{
            display: none;
            position: absolute;
            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            padding: 20px;
            top: 25px;
            right: 0px;
            z-index: 1;
            width: 200px;
            &.active{
                display: block;
            }
            form{
                button.apply-button{
                    width: 100%;
                    margin-top: 20px;
                    padding: 10px 25px;
                    border-radius: 100px;
                    background: #0092FF;
                    color: #FFFFFF;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                ul{
                    height: 110px;
                    overflow-y: auto;
                    li:first-child{
                        margin-top: 10px;
                    }
                    li{
                        font-size: 15px;
                        font-weight: 500;
                        color: #2C2C2C;
                        margin-top: 20px;
                        span.MuiCheckbox-root{
                            padding: 0;
                            span.MuiIconButton-label{
                                margin-top: -2px;
                                .MuiSvgIcon-root{
                                    fill: #2C2C2C;
                                    font-size: 1.3rem;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.api-usage-graph-block{
    // border: 1px solid red;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 25px;
    .chart-labels{
        display: flex;
        flex-flow: column;
        padding: 0;
        min-width: 120px;
        max-width: 140px;
        max-height: 200px;
        overflow: auto;
        // padding-right: 20px;
        margin-left: 20px;
        svg{
            margin: auto;
            cursor: pointer;
            color: #43425D;
            &:first-child{
                margin-bottom: 5px;
            }
            &:last-child{
                margin-top: 5px;
            }
        }
        .legend{
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            flex: 1 0 auto;
            padding-bottom: 10px;
            margin-top: 10px;
            // margin-left: 15px !important;
            border-bottom: 1px solid rgba(35, 36, 37, 0.2);
            .color-dot{
                width: 9px;
                height: 9px;
                border-radius: 50%;
            }
            .text{
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                margin-top: -5px;
                margin-left: 7px;
                width: calc(100% - 15px);
                h2{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #232425;
                }
                label{
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    color: rgba(35, 36, 37, 0.8);
                    margin-top: 5px;
                    word-break: break-all;
                }
            }
            &:last-child{
                border: unset;
                padding: 0;
            }
        }
    }
    .recharts-surface{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: rgba(35, 36, 37, 1);
        text:nth-child(2){
            font-size: 20px;
        }
        text:nth-child(3){
            font-size: 12px;
            font-weight: 500;
            opacity: 0.8;
        }
    }
}