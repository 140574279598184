.beneficiary-details {
  &-heading {
    margin-top: 15px;
    margin-bottom: 5px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
    }
  }
  .required_span {
    display: flex;
    align-items: flex-start;
    font-size: 8px !important;
    padding: 2px;
    margin-left: 5px;
    justify-content: flex-start;
  }
  .required:after {
    content: " *";
    color: red;
  }

  .show {
    visibility: visible;
  }
  .hide {
    visibility: hidden;
  }
  .in_not {
    position: absolute;
    right: 10px;
    height: 15px;
  }

  div.close-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }

  .MuiDialog-paper {
    overflow: visible !important;
  }

  &-subtitle {
    text-align: left;
    font: normal normal normal 15px/18px Montserrat;
    margin-bottom: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #808495;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .beneficiary-details-subtitle-all {
    text-align: left;
    font: normal normal normal 13px/16px Montserrat;
    font-weight: 600;
    letter-spacing: 0px;
    color: #808495;
    position: relative;
  }

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    .MuiOutlinedInput-input {
      padding: 10px 13px;
      font-family: "Montserrat";
    }

    div.field-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      gap: 32px;
      margin: 7px;
      label {
        align-self: center;
        display: flex;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #43425d;
        }
      }
      fieldset {
        border-radius: 10px;
      }
      input::placeholder {
        font-size: 13.5px;
        color: #999393 !important;
      }
    }

    .beneficiary-provider-code {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 7px 0px;
      label {
        align-self: center;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #43425d;
        }
      }
    }

    .beneficiary-provider-dropdown {
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .provider-show {
        width: 315px;
        padding: 8px 12px;
        border-radius: 10px;
        background: transparent;
        border: 1px solid #cbcbcb;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 20px;
          height: 20px;
          margin-left: auto;
          color: #6c6c6c;
        }
        &.show-border {
          border: 1px solid #0092ff;
        }
        &.show-border:hover {
          border: 1px solid #0092ff;
        }
      }
      .provider-show:hover {
        outline: none;
        border: 1px solid #000000;
      }
      .placeholder-text {
        color: #999393;
        font-weight: normal;
        opacity: 0.5;
      }
      .provider-text {
        color: #2b2b2b;
      }
      .provider-bank-option {
        display: none;
        position: absolute;
        background-color: #ffffff;
        top: 31px;
        right: 0;
        z-index: 50;
        width: 315px;
        &.active {
          display: block;
          border: 1px solid #0092ff;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top: 0;
        }
        .provider-options {
          background-color: #ffffff;
          margin-bottom: 5px;
          .option {
            font: normal normal 400 13px/24px Montserrat;
            color: #333;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 14px;
            height: 40px;
            &:hover {
              background-color: #f9f9f9;
            }
          }
        }
      }
    }

    .submit-btn-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .submit-beneficiary-details {
        outline: none;
        margin-top: 26px;
        margin-bottom: 18px;
        padding: 7px 30px;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        color: #d1d1d1;

        .rs-loader-wrapper {
          transform: translate(6px, 3px);
        }
      }
      .active-beneficiary-btn {
        border: none;
        background: #0092ff;
        color: #ffffff;
      }
    }
  }
}

.w-beneficiary-input {
  width: 315px;
}

.bordered-or {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 46%;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    background-color: #cbcbcb;
  }
  &::before {
    left: 0px;
  }
  &::after {
    right: 0px;
  }
}
