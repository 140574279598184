h3.module-header,
p.module-description {
  color: #0092ff;
  font-size: 0.725rem;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}

h3.module-header {
  font-size: 0.925rem;
  font-weight: 800;
  margin-bottom: 10px;
}

.carousel-status {
  display: none;
}

.carousel.carousel-slider {
  position: relative;

  .control-arrow {
    background-color: transparent;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    padding: 0;

    &::before {
      margin: 0;
    }

    &:hover {
      background-color: #f2f9ff;
    }

    &.control-prev {
      left: -7px;

      &::before {
        margin: 0 !important;
        border-right: 8px solid #0092ff;
      }
    }

    &.control-next {
      right: -7px;

      &::before {
        margin: 0 !important;
        border-left: 8px solid #0092ff;
      }
    }

    &.control-prev,
    &.control-next {
      top: 50%;
      transform: translate(0, -10px);
    }
  }

  .slider-wrapper {
    margin-left: 20px;
  }
}
