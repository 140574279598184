.skeleton{
    background-color: rgba(228, 228, 228, 0.767);
    margin: 10px 0px;
    
}
.skeleton.text{
    width: 100%;
    height: 12px;

}
.skeleton.title{
    width: 20%;
    height: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.skeleton.row{
    width: 100%;
    height: 18px;
    padding: 5px;
    margin-top: 10px;
    
}
.skeleton.edit{
    border-radius: 20px;
    height: 35px;
    width: 37%;
}

.skeleton.buttons{
    width: 20%;
    height: 37px;
    border-radius: 20px;
}
.skeleton.buttons3{
    width: 25%;
    height: 37px;
    border-radius: 20px;
}
.top-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 11px;
    gap: 20px;

}
.analytics-wrapper{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 18px;
}
.button-div{
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    padding: 0 14px;
    width: 80%;
    height: 60px;
}
.header{
    width: 100%;
    height: 58px;
    


}
.card{
    width: 32%;
    height: 270px;
    border-radius: 10px;
    padding: 10px;
}
.big_card{
    height: 270px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}
.analytics{
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    // background-color: white;

}
.title-holder{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
}
.skeleton-datatable-wrapper{
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: white;
    height: 800px;
    position: relative;
    animation: example 1.5s infinite;
}
.shimmer-wrapper{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    animation: loading 1.5s infinite;

}
.shimmer{
    width: 18%;
    height: 45%;
    background-color: rgba(255, 255, 255, 0.3);
    // transform: skewY(30deg);
}

@keyframes loading{
    0%{transform: translateX(-150%)}

    50%{transform: translateX(-60%)}

    100%{transform: translateX(100%)}

}
@keyframes example {
    0%   {transform:opacity(0.5);}
    50%  {transform:opacity(0.8);}
    100% {transform:opacity(1);}
}