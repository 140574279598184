.signup{
	width: 100%;
    height: 100%;
	display: flex;
	flex-flow: row;
	.signup-section{
		width: 50%;
		height: calc(100% - 70px);
		display: flex;
		justify-content: center;
		overflow-y: auto;
		overflow-x: hidden;
		.form-section{
			width: 65%;
			display: flex;
			align-items: center;
			flex-flow: column;
			margin-top: 60px;
		    h1{
		    	font-size: 17px;
		    	color: #4D4F5C;
		    	font-weight: 400;
		    	margin-top: 25px;
		    }
		    .input-section{
		    	display: flex;
			    flex-flow: column;
			    align-items: center;
		    	margin: 15px 0 0 0;
			    width: 100%;
			    form{
			    	width: 100%;
			    	.inputs{
			    		display: flex;
					    flex-flow: column;
					    width: 100%;
			    		input{
			    			width: 100%;
			    			margin-top: 20px;
			    		}
			    	}
				    .agree-terms{
				    	width: 100%;
				    	margin-top: 30px;
				    	display: flex;
				    	align-items: center;
				    	input{
				    		margin: 0;
				    		border-radius: 2px;
				    		border: 1px solid #808495;
				    	}
				    	label{
				    		font-size: 13px;
				    		color: #43425D;
				    		margin-left: 10px;
				    		a{
				    			margin: 0;
						    	color: #0092FF;
						    	font-size: 13px;
						    	background: transparent;
								cursor: pointer;
								&:hover {
									text-decoration: underline !important;
								}
				    		}
				    	}
				    }
				    button.confirm-button{
				    	display: flex;
				    	margin: 30px auto 0px auto;
				    	border-radius: 25px;
						background: #0092FF;
						padding: 10px 40px;
						color: #FFFFFF;
						font-size: 16px;
						font-weight: 400;
						border: 1px solid transparent !important;
				    }
				    span.error{
				    	color: #ff0000;
				    	font-size: 10px;
				    	font-weight: 500;
				    	margin-top: 4px;
				    }
				}
		    	.signin-message{
			    	margin: 30px 0px;
			    	p{
			    		font-size: 13px;
			    		color: #43425D;
				    	a{
				    		margin: 0;
					    	color: #0092FF;
					    	font-size: 13px;
					    	background: transparent;
					    }
					}
			    }
		    }
		    .verify-email-section{
		    	display: flex;
				flex-flow: column;
				align-items: center;
		    	label{
		    		margin-top: 20px;
					font-size: 15px;
					line-height: 24px;
					color: #43425D;
					text-align: center;
		    	}
		    	a{
		    		margin-top: 20px;
		    		font-size: 14px;
		    		font-weight: 400;
		    		color: #0092FF;
		    	}
		    }
		}
	}
}

@media screen and (max-width: 1280px){
    .signup{
        .signup-section{
            margin: auto;
            width: 65%;
        }
    }
}

@media screen and (max-width: 710px){
    .signup{
        .signup-section{
            margin: auto;
            width: 100%;
            .form-section{
                img{
                    width: 200px;
                }
            }
        }
    }
}
