.add-user-page {
  .user-divider {
    border-top: 1px solid #cbcbcb;
    margin: 2rem 0;
  }
  .back-btn {
    display: flex;
    align-items: center;
    width: 30%;
    margin-bottom: 30px;
    grid-gap: 1rem;
    gap: 1rem;
    height: 10%;
    img {
      width: 1.2rem;
      cursor: pointer;
    }
    h1.heading {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      color: #43425d;
      display: flex;
      align-items: center;
      .company-name-highlight {
        font-size: 1.2rem;
        font-weight: 600;
        padding-left: 0.5rem;
        color: #0092ff;
      }
    }
    p.heading {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      color: #43425d;
      display: flex;
      align-items: center;
    }
  }
  .add-user-content {
    padding: 18px 0px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    .add-user-card {
      border-radius: 10px;
      margin: 2rem;
      margin-top: 1rem;
    }
  }
}
