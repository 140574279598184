@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
.tableWrapper {
  margin-top: 1.3rem;
  color: #575757;
  font-size: 12px;
  // min-height: 500px;
  position: relative;
  // overflow-x: scroll;

  .hide {
    display: flex;
    visibility: hidden;
  }
  .show {
    display: flex;
    visibility: visible;
  }
  table {
    background-color: white;
    width: 100%;
  }

  .tableContainer {
    overflow: scroll;
    height: calc(100vh - 398px);
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #e5f4ff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0092ff;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #0092ff;
      border-radius: 10px;
    }
  }

  .tableContainerLoading {
    overflow: scroll;
    height: calc(100vh - 315px);
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #e5f4ff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0092ff;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #0092ff;
      border-radius: 10px;
    }
  }

  thead th {
    background: #f5f6fa;
    // padding: 0.9rem 0.7rem;
    padding: 0.9rem 1.5rem;
    text-align: left;
    text-transform: capitalize;
    .popup-container {
      display: none;
      min-width: 140px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 11px 0px rgb(0 0 0 / 10%);
      position: absolute;
      top: 100%;
      animation: 300ms popup ease;
      right: 50%;
      transform: translateX(50%);
      padding: 0 0.3rem;
      overflow: scroll;
      max-height: 300px;
      .sortBtn {
        color: #0092ff;
        font-weight: 400;
      }
      .clearFilterBtn {
        color: #0092ff;
        .clearicon {
          padding-right: 0.3rem;
        }
      }
      .sortArrowIcon,
      .clearicon {
        color: #0092ff;
      }
      .clearFilterBtn {
        font-weight: 400;
      }
      .statusSort {
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          input {
            margin-left: 0.2rem;
          }
        }
      }
      div {
        color: #000;
        border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
        padding: 0.7rem 0.5rem;
      }
    }
    &:hover {
      .popup-container {
        display: block;
      }
    }

    .angleDownIcon {
      color: #000;
    }
    div {
      position: relative;
      display: flex;
      gap: 0.4rem;
      align-items: center;
      // justify-content: center;
      white-space: nowrap;
      justify-content: flex-start;
      text-align: left;
      gap: 10px;
      div {
        color: #1675e0;
      }
    }
  }

  thead th {
    &:hover {
      .popup-container-disable {
        display: none;
      }
    }
  }

  tbody {
    td {
      white-space: nowrap;
      // padding: 0.65rem;
      padding: 0.9rem 1.5rem;
      text-align: left;
      // &:nth-child(1),
      // &:nth-child(3) {
      //   color: #1675e0;
      // }
      line-height: 2;
      .status-update {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        img {
          cursor: pointer;
        }
      }
    }
    tr {
      font-weight: 500;
      font-family: "Montessrat";
      border-bottom: 1px solid lightgray !important;
    }
  }
  .noMatchingRecordsContainer {
    background-color: #fff;
    padding-top: 0.5rem;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50%);
  }

  .skeletonRecordsContainer {
    background-color: #fff;
    padding-top: 0.5rem;
    position: absolute;
    top: 10%;
    right: 0%;
    left: 0%;
    // height: calc(100vh - 900px);
    // bottom: 0%;
    // transform: translateX(50%);
  }
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-disable {
  cursor: not-allowed;
}

.text-center {
  text-align: center;
}
.pagination {
  align-items: center;
  display: flex;
  padding: 0px 27px 0px 0px;
  gap: 1.1rem;
  justify-content: flex-end;
  margin: 20px 0;
  span {
    font-size: 1.25rem;
    font-weight: 400;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 0.7rem;
  }
  div {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin: 0;
    font-size: 13px;
    border: 1px solid #f5f5f5;
    color: #0092ff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #0092ff;
      color: white;
      cursor: pointer;
    }
    &:focus {
      border-color: #0092ff;
    }
    &.active {
      border: 1px solid #0092ff;
    }
  }
  .next-page-btn,
  .previous-page-btn {
    border-radius: 0px;
    border: 1px solid #fff;
    &:hover {
      background-color: #fff;
      color: #fff;
    }
  }
}

@keyframes popup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
