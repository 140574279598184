.header {
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  padding: 20px;
  .left-side {
    text-align: center;
    img {
      width: 155px;
    }
  }
  .right-side {
    .user {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      .btn_mail {
        align-items: center;
        background: #fff;
        border: 1px solid #0092ff;
        border-radius: 20px;
        color: #0092ff;
        cursor: pointer;
        display: flex;
        font-size: 10px;
        font-weight: 600;
        gap: 6px;
        justify-content: center;
        margin: 0;
        outline: none;
        padding: 6px 20px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      p {
        margin-right: 50px;
        font-size: 17px;
        font-weight: 500;
        color: #4d565c;
        line-height: 24px;
        cursor: pointer;
      }
      .header-btn {
        border-radius: 20px;
        font-size: 10px;
        font-weight: 600;
        padding: 6px 20px;
      }
      .product-status-btn {
        background: #daf7e8;
        color: #34af71;
        border: 1px solid #34af71;
      }
      .change-log-btn {
        background: #e4f2fd;
        color: #0092ff;
        border: 1px solid #0092ff;
      }
      .dropdown {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
          font-size: 15px;
          line-height: 25px;
          display: flex;
          align-items: center;
          svg {
            font-size: 22px;
            margin-top: 2px;
          }
        }
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
          padding: 12px 16px;
          top: 45px;
          right: -20px;
          z-index: 99;
          border-radius: 10px;
          ul {
            padding: 0px 10px;
            margin: 0;
            li {
              text-align: center;
              font-size: 15px;
              font-weight: 500;
              color: #4d565c;
              line-height: 24px;
              padding: 0px 0px 5px 0px;
              cursor: pointer;
              border-bottom: 1px solid rgba(0, 0, 0, 0.03);
              &:last-child {
                border: unset;
              }
              &.logout {
                border: 1px solid #0092ff;
                background: #0092ff;
                color: #ffffff;
                padding: 7px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px auto 0px auto;
                width: max-content;
                padding: 7px 20px;
                border-radius: 100px;
                font-size: 14px;
              }
            }
          }
          &.active {
            display: block;
          }
          &:before {
            content: "";
            position: absolute;
            top: -10px;
            left: 140px;
            border-style: solid;
            border-width: 0 12px 12px;
            border-color: #ffffff transparent;
            display: block;
            width: 0;
            z-index: 1;
          }
        }
      }
      .avatar {
        width: 38px;
        height: 38px;
        background: #f9f9f9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
