div.no-data-message{
	display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 350px;
    max-width: 80%;
    margin: 20px auto;
    p, label{
    	font-style: italic;
		font-size: 14px;
        font-weight: 500;
	    color: #4D565C;
    }
    label{
    	margin: 10px 0 0 auto;
    }
}
.shimmer-wrapper{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    animation: loading 1.5s infinite;

}
.shimmer{
    width: 18%;
    height: 45%;
    background-color: rgba(255, 255, 255, 0.3);
    // transform: skewY(30deg);
}

@keyframes loading{
    0%{transform: translateX(-150%)}

    50%{transform: translateX(-60%)}

    100%{transform: translateX(100%)}

}