.table-modal-btn {
  margin-left: 20px;
  padding: 10px 25px;
  border-radius: 100px;
  background: #0092ff;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
}

.MuiDialog-paper {
  overflow: visible !important;
}

.va-details {
  &-heading {
    margin-top: 10px;
    margin-bottom: 5px;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #43425d;
    }
  }

  .MuiDialog-paperWidthSm {
    max-width: 1000px !important;
  }

  div.close-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }

  &-container {
    // width: 600px;
    margin-top: 10px;
    display: flex;
    padding: 8px 0 14px 0;
    .left-detail-block {
      font-size: 14px;
      border-radius: 5px;
      background-color: #f7f7f7;
      padding: 20px;

      table {
        width: 520px;
      }

      table tr th {
        color: #43425d;
        text-align: left;
        font-weight: 600;
      }

      table tr td {
        color: #000000;
        font-weight: 500;
      }

      table tr td,
      th {
        padding: 10px;
      }
    }

    .right-qr-block-links {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 24px;

      // img.qr-image {
      //   height: 120px;
      // }
      .links {
        display: flex;
        align-items: center;
        justify-content: center;
        .copy-img {
          height: 16px;
          width: 20px;
        }
      }

      .download-btn {
        // background-color: #e5f4ff;
        border-radius: 7px;
        color: #0092ff;
        font-size: 10px;
        height: 12px;
        /* padding: 11px; */
        margin: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 15px;
        font-weight: 600;
        /* padding: 8px 16px; */
      }
    }
  }
}
