.submenu-wrapper {
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 20px 20px 20px 20px;
  position: relative;
  transition: 0.3s all;

  // height: 97%;
  .submenu-bar {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 320px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fafafa;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0000000a;
    background-color: #ffffff;
    padding-top: 10px;

    ul.nav-primary {
      //   overflow-x: hidden;
      overflow-y: visible;
      li {
        display: flex;
        flex-flow: column;

        .nav-link,
        a {
          position: relative;
          flex-flow: row;
          align-items: center;
          cursor: pointer;
          padding: 16px 20px;
          padding-bottom: 5px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #43425d;
          display: flex;
          align-items: center;
          width: max-content;
          width: 100%;
          &.sub-item {
            padding-top: 10px;
            padding-bottom: 10px;
            // padding-left: 0;
            padding-right: 3px;
            font-size: 12px;
            padding-left: 10px;
          }
          .dropdown-icon {
            margin-left: auto;
            &.dropdown-closed {
              transform: rotate(90deg);
            }
            &.dropdown-open {
              transform: rotate(-90deg);
            }
          }
          .display-name {
            font-size: 14px;
            transition-duration: 300ms;
            opacity: 0;
            white-space: nowrap;
            &.no-color {
              color: black !important;
              font-weight: 600;
              cursor: default;
            }
          }
          img {
            filter: grayscale(100%);
          }

          img.module-icon {
            margin-right: 20px;
            height: 20px;
            width: 20px;
            &.no-color {
              filter: grayscale(100%);
            }
          }
          img.sub-module-icon {
            margin-right: 12px;
            margin-left: 13px;
            height: 12px;
            width: 17px;
          }

          &.active {
            border-color: rgba(0, 146, 255, 1);
            color: rgba(0, 146, 255, 1);
            img {
              filter: unset;
            }
          }
        }
      }
    }

    .info-section-wrapper {
      background: #f2f9ff 0% 0% no-repeat padding-box;
      border-radius: 0px 0px 10px 0px;
      .info-section-top,
      .info-section-scrollable {
        padding: 20px;
        h3.module-header,
        p {
          color: #000000;
          font-size: 0.825rem;
          font-weight: 400;
          line-height: 140%;
        }
        h3.module-header {
          font-size: 0.825rem;
          font-weight: 600;
          margin-bottom: 5px;
        }
        h3.update-section-header {
          font-size: 0.825rem;
          font-weight: 600;
          line-height: 18px;
          color: #000000;
          margin-bottom: 10px;
        }
        .info-section-header {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            margin-bottom: 10px;
            width: 18px;
            height: 18px;
          }
        }
      }
      hr {
        width: 85%;
        text-align: center;
        color: #d6e6f2;
        background-color: #d6e6f2;
        font-weight: 600;
        height: 1px;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
      }
      .info-section-scrollable {
        p.module-description {
          width: 80%;
          font-size: 0.725rem;
        }
      }
    }
  }

  &.collapsed-submenu {
    width: 100px;
  }

  &.extended-submenu {
    width: 300px;
    @media (min-width: 960px) {
      // min-width: 300px;
      min-width: 290px;
    }
    overflow-x: hidden;
    // z-index: 100;
    z-index: 1;
    .display-name {
      opacity: 1 !important;
    }
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1023px) {
  .submenu-wrapper {
    .submenu-bar {
      ul.nav-primary {
        li {
          .nav-link,
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .submenu-wrapper {
    &.extended-submenu {
      width: 230px;
    }
  }
}

.nav-dropdown-elements-container {
  overflow: hidden;
  // padding-left: 0.9rem;
  padding-top: 0.25rem;

  &:not(.active) {
    transition-duration: 500ms;
    max-height: 0;
  }
  &.active {
    max-height: 2000px;
    transition-duration: 2000ms;
  }
}
.nav-link {
  .display-name {
    font-weight: 600;
  }
}
