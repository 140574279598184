.user-config-success {
    .MuiDialog-paper {
      position: relative;
      overflow: visible !important;
    }
  
    div.close-success-popup {
      right: -28px;
      top: -28px;
      position: absolute;
      z-index: 100;
      background: #f2f9ff 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
  
      svg {
        color: #0092ff;
      }
    }
  
    .dialog-heading {
      margin-top: 10px;
      margin-bottom: 5px;
  
      p {
        font-size: 22px;
        font-weight: 600;
        color: #43425d;
      }
    }
  
    .dialog-content-container {
      width: 475px;
  
      .success-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        height: 150px;
        .img-copy{
          padding: 5px;
        }
  
        h3 {
          font-size: 16px;
          font-weight: 600;
          color: #4d4f5c;
        }
  
        p {
          color: #808495;
          font-size: 12px;
          text-align: center;
        }
      }
  
      .success-account-details {
        border-radius: 5px;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 30%;
        position: relative;
  
        table {
          margin-left: 10px;
  
          td {
            font-size: 12px;
            font-weight: 500;
            color: #43425d;
            padding: 12px;
            text-align: left;
          }
  
          .detail-heading {
            font-weight: 600;
            color: #43425d;
          }
  
          .detail-value {
            font-weight: 500;
            color: #000000;
          }
          .active {
            padding: 7px;
            text-align: center;
            width: 70px;
            background-color: rgba(137, 205, 36, 0.769);
            border-radius: 17px;
          }
          .in_active {
            padding: 7px;
            text-align: center;
            width: 70px;
            background-color: rgba(238, 111, 111, 0.801);
            border-radius: 17px;
          }
  
          .copy-link-icon {
            cursor: pointer;
            width: 14px;
            margin-left: 8px;
  
            &:hover {
              opacity: 0.5;
            }
          }
        }
  
        
      }
  
      .success-btn-container {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy-account-details {
          border: none;
      outline: none;
      padding: 7px 26px;
      border-radius: 100px;
      background: #e3f1fb;
      color: #0092ff;
      font-size: 13px;
      display: flex;
      font-weight: 500;
      align-items: center;
      gap: 5px;
      line-height: 20px;
      
          img {
            width: 15px;
            padding: 1px;
            margin: 1px;
          }
        }
        .success-btn-done {
          border: none;
          outline: none;
          padding: 7px 30px;
          border-radius: 100px;
          background: #0092ff;
          color: #ffffff;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
  