.warn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem;
  width: 100%;
  height: 350px;
  background-color: #fff;
  border-radius: 10px;
  .warn-text {
    font-weight: 600;
  }
}
