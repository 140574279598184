.staging-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  // margin-top: 18px;
  margin-top: 8px;
  background-color: #f5f5f5;

  .menu-container-dev {
    background-color: #ffffff;
  }

  .submenu-and-content {
    width: 100%;
    // height: calc(100% - 70px);
    height: calc(95vh - 70px);
    display: flex;
    position: relative;

    .content {
      padding: 20px 20px 20px 0px;
      width: 100%;
      height: 100%;
      background: #f5f5f5;
      overflow-y: auto;
      position: relative;
      overflow-x: hidden;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .staging-dashboard .submenu-and-content .content {
    padding: 20px 20px 20px 0px;
    width: 100%;
    height: 95%;
    flex: 1;
    background: #f5f5f5;
    overflow-y: auto;
    position: relative;
  }
}
