// COLORS:

$theme-color: #0092ff;
$color-gray: #a5a5a5;
$color-white-primary: #ffffff;
$color-background-secondary: rgba(229, 244, 255, 1);
$color-background-disabled: #e8e9ec;

.money-transfer-btn {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  user-select: none;
  color: $color-white-primary;
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 20px;
  background: $theme-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  gap: 6px;
}

.w-beneficiary-input {
  width: 315px;
}
