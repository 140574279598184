.enable-2fa {
  .manage-2fa-heading {
    margin-bottom: 5px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
    }
  }


}
